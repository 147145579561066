import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Modal from "../../shared/components/UIElements/Modal";
import { Default } from "react-awesome-spinners";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

const Submit = ({
  selectieUser,
  formState,
  inputHandler,
  navigation,
  pCuCard,
}) => {
  let history = useHistory();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showSuccess2, setShowSuccess2] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  //const [registerCandidateState, setRegisterCandidateState] = useState();

  /* const verifyCanSubmit = async (registerCandidate) => {
    const formData = new FormData();
    formData.append("facultate", selectieUser.split(",")[0]);
    formData.append("nivel", selectieUser.split(",")[1]);
    formData.append("ififr", selectieUser.split(",")[2]);
    formData.append("program", selectieUser.split(",")[3]);
    console.log("selectieUser :", selectieUser);

    const response = await fetch(
      //"https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users",
      "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/verifyCanSubmit",
      {
        method: "POST",
        headers: {},
        body: formData,
      }
    );
    const responseData = await response.json();

    if (responseData.data.canSubmit === true) {
      setRegisterCandidateState(false);
    } else if (responseData.data.canSubmit === false) {
      setRegisterCandidateState(true);
    }
  }; */

  const sendRequest = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append(
        "firstActualName",
        formState.inputs.firstActualName.value
      );
      formData.append("firstBornName", formState.inputs.firstBornName.value);
      formData.append("lastName", formState.inputs.lastName.value);
      formData.append("fatherInitial", formState.inputs.fatherInitial.value);
      formData.append("cnp", formState.inputs.cnp.value);
      formData.append("bornDate", formState.inputs.bornDate.value);
      formData.append("bornPlace", formState.inputs.bornPlace.value);
      formData.append("bornDistrict", formState.inputs.bornDistrict.value);
      formData.append("bornCountry", formState.inputs.bornCountry.value);
      formData.append("seriesCart", formState.inputs.seriesCart.value);
      formData.append("numberCart", formState.inputs.numberCart.value);
      formData.append("setCart", formState.inputs.setCart.value);
      formData.append(
        "releaseDateCart",
        formState.inputs.releaseDateCart.value
      );
      formData.append("currentCity", formState.inputs.currentCity.value);
      formData.append(
        "currentDistrict",
        formState.inputs.currentDistrict.value
      );
      formData.append("currentCountry", formState.inputs.currentCountry.value);
      formData.append("phone", formState.inputs.phone.value);
      formData.append("email", formState.inputs.email.value);
      formData.append("mediabac", formState.inputs.mediabac.value);
      formData.append("medialicenta", formState.inputs.medialicenta.value);
      formData.append("facultate", selectieUser.split(",")[0]);
      formData.append("nivel", selectieUser.split(",")[1]);
      formData.append("ififr", selectieUser.split(",")[2]);
      formData.append("program", selectieUser.split(",")[3]);

      /* formData.append(
        "userLicenta",
        selectieUser.includes("licenta") ?  : ""
      );
      formData.append(
        "userMaster",
        selectieUser.includes("master") ? selectieUser : ""
      ); */
      formData.append("imagine", formState.inputs.imagine.value);
      formData.append("diplomabac", formState.inputs.diplomabac.value);
      formData.append("foaiematricola", formState.inputs.foaiematricola.value);
      formData.append("diplomalicenta", formState.inputs.diplomalicenta.value);
      formData.append(
        "suplimentdiploma",
        formState.inputs.suplimentdiploma.value
      );
      formData.append(
        "certificatnastere",
        formState.inputs.certificatnastere.value
      );
      formData.append(
        "certificatcasatorie",
        formState.inputs.certificatcasatorie.value
      );
      formData.append(
        "carteidentitate",
        formState.inputs.carteidentitate.value
      );
      formData.append(
        "adeverintamedicala",
        formState.inputs.adeverintamedicala.value
      );
      formData.append(
        "chitantaadmitere",
        formState.inputs.chitantaadmitere.value
      );
      formData.append("platacucardul", pCuCard === true ? "true" : false);

      const response = await fetch(
        //"https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users",
        "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users",
        {
          method: "POST",
          headers: {},
          body: formData,
        }
      );
      //const responseData = await response.json();
      // Throw error
      if (!response.ok) {
        openShowAlertHandler2();
        /* throw new Error(responseData.error); */
      }

      setIsLoading(false);

      if (pCuCard === true) {
        //openShowSuccesHandler2(true);
        openShowSuccesHandler(true);
        setTimeout(() => history.push("/plata-cu-card-online"), 5000);
      } else {
        openShowSuccesHandler2(true);
      }
    } catch (err) {
      if (err.message === "error") {
        setError("Eroare | Datele nu s-au trimis");
      } else {
        setError("Eroare trimitere date");
      }
      //setIsLoading(false);
    }
  };

  const openShowSuccesHandler = useCallback(() => setShowSuccess(true), []);
  const openShowSuccesHandler2 = useCallback(() => setShowSuccess2(true), []);
  const openShowAlertHandler2 = useCallback(() => setShowAlert2(true), []);

  useEffect(() => {
    //debugger;

    //if (registerCandidateState === true) {
    sendRequest();
    //console.log("sendRequest()");
    //}
    /* else {
      try {
        verifyCanSubmit(registerCandidateState);
      } catch (err) {
        console.log("error verify submit");
      }
    } */
  }, []);

  const errorHandler = () => {
    setError(null);
  };

  return (
    !isLoading && (
      <>
        <Modal
          show={showSuccess}
          header={"Success"}
          contentClass=""
          footerClass=""
        >
          <p style={{ fontSize: "22px", marginBottom: "20px" }}>
            Cererea dumneavoastră a fost trimisă cu succes
          </p>
          <p style={{ fontSize: "22px", marginBottom: "30px" }}>Vă mulțumim!</p>

          <footer>
            <p style={{ fontSize: "22px", marginBottom: "20px" }}>
              NU INCHIDETI PAGINA, ASTEPTATI 5 SECUNDE!!!
            </p>
            <Default size={64} color={"#1f8f30"} sizeUnit={"px"} />
            <p style={{ fontSize: "22px", marginBottom: "20px" }}>
              Va redirectionam automat catre PAGINA DE PLATA ONLINE
            </p>
            {/* <Link to="/finalizat" className="btn buttonCustom">
            Catre Site (ueb.ro)
          </Link> */}
          </footer>
        </Modal>
        <Modal
          show={showSuccess2}
          header={"Success"}
          contentClass=""
          footerClass=""
        >
          <p style={{ fontSize: "22px", marginBottom: "20px" }}>
            Cererea dumneavoastră a fost trimisă cu succes
          </p>
          <p style={{ fontSize: "22px", marginBottom: "30px" }}>Vă mulțumim!</p>

          <footer>
            <Link to="/finalizat" className="btn buttonCustom">
              Catre Site (ueb.ro)
            </Link>
          </footer>
        </Modal>
        <Modal
          show={showAlert2}
          header={"Alert"}
          contentClass=""
          footerClass=""
        >
          <p style={{ color: "red", fontSize: "24px", marginBottom: "20px" }}>
            Nu s-a putut procesa cererea dvs. va rog sa reincercati. .
          </p>
          <p style={{ color: "red", fontSize: "24px", marginBottom: "30px" }}>
            Ne cerem scuze
          </p>

          <footer>
            <Link to="/finalizat" className="btn buttonCustom">
              Catre Site (ueb.ro)
            </Link>
          </footer>
        </Modal>
        {/* <Modal
          show={registerCandidateState === false}
          header={"Alert"}
          contentClass=""
          footerClass=""
        >
          <p style={{ color: "red", fontSize: "24px", marginBottom: "20px" }}>
            Va anuntam ca inscrierile pentru facultatea aleasa de dvs. s-au
            terminat. Cel mai probabil nu ati dat un refresh la pagina web a
            formularului (admitere.ueb.ro/pas-1)
          </p>

          <footer>
            <Link to="/finalizat" className="btn buttonCustom">
              Catre Site (ueb.ro)
            </Link>
          </footer>
        </Modal> */}
        <ErrorModal error={error} onClear={errorHandler} />
      </>
    )
  );
};

export default Submit;

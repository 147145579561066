import React, { useReducer, useCallback, useState, useEffect } from "react";
import { useStep } from "react-hooks-helper";

import Header from "../components/Header";
import Navigator from "../components/Navigator";
import Formular from "../components/Formular";
import ListaFacultati from "../components/ListaFacultati";
import UploadDocuments from "../components/UploadDocuments";
import Submit from "../components/Submit";

import "./MultiStepFormPage.css";

const steps = [
  { id: "facultati" },
  { id: "formular" },
  { id: "documents" },
  { id: "submit" },
];

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else if (
          action.selectieUser.includes("licenta") &&
          (inputId === "medialicenta" ||
            inputId === "diplomalicenta" ||
            inputId === "suplimentdiploma")
        ) {
          continue; // skip validation for medialicenta and diplomalicenta if licenta is selected
        } else if (
          action.selectieUser.includes("master") &&
          (inputId === "mediabac" || inputId === "diplomabac")
        ) {
          continue; // skip validation for mediabac and diplomabac if master is selected
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      console.log("new state: ", {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: {
            value: action.value,
            isValid: action.value !== "" ? true : action.isValid,
          },
        },
        isValid: formIsValid,
      });

      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: {
            value: action.value,
            isValid: action.value !== "" ? true : action.isValid,
          },
        },
        isValid: formIsValid,
      };

    default:
      return state;
  }
};

/* const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputId]: {
            value: action.value,
            isValid: action.value !== "" ? true : action.isValid,
          },
        },
        isValid: formIsValid,
      };

    default:
      return state;
  }
}; */

const MultiStepFormPage = () => {
  const [checked, setChecked] = useState(false);
  const [selectieUser, setSelectieUser] = useState("");
  const [pCuChitanta, setPCuChitanta] = useState(false);
  const [pCuCard, setPCuCard] = useState(false);
  const [isLicentaValid, setIsLicentaValid] = useState(false);
  //console.log(selectieUser);

  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      firstActualName: {
        value: "",

        isValid: false,
      },
      firstBornName: {
        value: "",

        isValid: false,
      },
      lastName: {
        value: "",

        isValid: false,
      },
      fatherInitial: {
        value: "",

        isValid: false,
      },
      cnp: {
        value: "",

        isValid: false,
      },
      bornDate: {
        value: "",

        isValid: false,
      },
      bornPlace: {
        value: "",

        isValid: false,
      },
      bornDistrict: {
        value: "",

        isValid: false,
      },
      bornCountry: {
        value: "",

        isValid: false,
      },
      seriesCart: {
        value: "",

        isValid: false,
      },
      numberCart: {
        value: "",

        isValid: false,
      },
      setCart: {
        value: "",

        isValid: false,
      },
      releaseDateCart: {
        value: "",

        isValid: false,
      },
      currentCity: {
        value: "",

        isValid: false,
      },
      currentDistrict: {
        value: "",

        isValid: false,
      },
      currentCountry: {
        value: "",

        isValid: false,
      },
      phone: {
        value: "",

        isValid: false,
      },
      email: {
        value: "",

        isValid: false,
      },
      mediabac: {
        value: "",

        isValid: false,
        //selectieUser.includes("licenta") === true ? false : true,
      },
      medialicenta: {
        value: "",

        isValid: false,
        //selectieUser.includes("master") === true ? false : true,
      },
      imagine: {
        value: "",

        isValid: false,
      },
      diplomabac: {
        value: "",

        isValid: false,
      },
      foaiematricola: {
        value: "",

        isValid: true,
      },
      diplomalicenta: {
        value: "",

        isValid: false,
        //selectieUser.includes("licenta") === true ? false : true
      },
      suplimentdiploma: {
        value: "",

        isValid: false,
        //selectieUser.includes("licenta") === true ? false : true
      },
      certificatnastere: {
        value: "",

        isValid: false,
      },
      certificatcasatorie: {
        value: "",

        isValid: true,
      },
      carteidentitate: {
        value: "",

        isValid: false,
      },
      adeverintamedicala: {
        value: "",

        isValid: false,
      },
      chitantaadmitere: {
        value: "",

        isValid: true,
      },
    },

    isValid: false,
  });

  useEffect(() => {
    console.log("isLicentaValid :", isLicentaValid);
  }, [isLicentaValid]);

  useEffect(() => {
    console.log("selectieUserMulti :", selectieUser);
  }, [selectieUser]);

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,

        selectieUser: selectieUser,
      });
    },
    [dispatch, selectieUser]
  );

  const inputHandlerCheckbox = () => {
    //console.log("checked consimtamant");
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  /* checkboxex pas 1 */
  const [clickedRadio, setClickedRadio] = useState();
  const [clickedRadioLicentaMaster, setClickedRadioLicentaMaster] = useState();
  const [clickedRadioFrecvNon, setClickedRadioFrecvNon] = useState();
  const [clickedRadioStudii, setClickedRadioStudii] = useState();
  const [clickedRadioStudiiMaster, setClickedRadioStudiiMaster] = useState();

  const radioHandler = (param) => {
    console.log("param :", param);
    setClickedRadio(param);
    setClickedRadioLicentaMaster();
    setClickedRadioFrecvNon();
    setClickedRadioStudii();
  };

  const radioHandlerLicentaMaster = (param) => {
    setClickedRadioLicentaMaster(param);
    /*  setClickedRadioFrecvNon();
    setClickedRadioStudii();
    setClickedRadioStudiiMaster(); */
  };

  const radioHandlerFrecvNon = (param) => {
    setClickedRadioFrecvNon(param);
    setClickedRadioStudii();
    setClickedRadioStudiiMaster();
  };

  const radioHandlerStudii = (param) => {
    setClickedRadioStudiiMaster();
    setClickedRadioStudii(param);
    if (document.getElementById(param).value.includes("licenta")) {
      if (formState.inputs.medialicenta.isValid === false) {
        if (formState.inputs.diplomalicenta.value === "")
          //setIsLicentaValid(true);
          dispatch({
            type: "isLicentaValid",
            isLicentaValid: true,
          });
      }
    }
    setSelectieUser(document.getElementById(param).value);
  };

  const radioHandlerStudiiMaster = (param) => {
    setClickedRadioStudiiMaster(param);
    setClickedRadioStudii();
    setSelectieUser(document.getElementById(param).value);
  };

  const plataCuChitanta = () => {
    setPCuCard(false);
    setPCuChitanta(true);
  };

  const plataCuCard = () => {
    setPCuChitanta(false);
    setPCuCard(true);
  };

  const { step, navigation, index } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = {
    selectieUser,
    clickedRadio,
    clickedRadioLicentaMaster,
    clickedRadioFrecvNon,
    clickedRadioStudii,
    clickedRadioStudiiMaster,
    radioHandler,
    radioHandlerLicentaMaster,
    radioHandlerFrecvNon,
    radioHandlerStudii,
    radioHandlerStudiiMaster,
    formState,
    inputHandler,
    navigation,
    index,
    inputHandlerCheckbox,
    checked,
    plataCuChitanta,
    pCuChitanta,
    plataCuCard,
    pCuCard,
  };

  switch (id) {
    case "facultati":
      return (
        <div className="container">
          <Header />
          <Navigator {...props} />
          <ListaFacultati {...props} />
        </div>
      );
    case "formular":
      return (
        <div className="container">
          <Header />
          <Navigator {...props} />
          <Formular {...props} />
        </div>
      );
    case "documents":
      return (
        <div className="container">
          <Header />
          <Navigator {...props} />
          <UploadDocuments {...props} />
        </div>
      );
    case "submit":
      return (
        <div className="container">
          <Header />
          <Navigator {...props} />
          <Submit {...props} />
        </div>
      );
    default:
      return null;
  }
};

export default MultiStepFormPage;

import React, { useEffect, useState } from "react";

import ImageUpload from "../../shared/components/FormElements/ImageUpload";

import FileUpload from "../../shared/components/FormElements/FileUpload";

import FileUploadOnlyPdf from "../../shared/components/FormElements/FileUploadOnlyPdf";

import Button from "../../shared/components/FormElements/Button";

import "./UploadDocuments.css";

const UploadDocuments = ({
  selectieUser,
  formState,
  inputHandler,
  navigation,
  inputHandlerCheckbox,
  checked,
  plataCuChitanta,
  pCuChitanta,
  plataCuCard,
  pCuCard,
}) => {
  const { go } = navigation;

  const [valueChitanta, setValueChitanta] = useState("sters");
  //const[platescCuCard, setPlatestCuCard] = useState(false);
  useEffect(() => {
    console.log("selectieUser :", selectieUser);
  }, [selectieUser]);

  const setChitanta = (param) => {
    setValueChitanta(param);
    //setPlatestCuCard(false);
  };

  //console.log(valueChitanta);
  //console.log(formState.inputs.chitantaadmitere.value);
  //console.log(pCuCard, formState.isValid);

  //console.log(pCuCard, formState.inputs.chitantaadmitere.value, checked);

  return (
    <>
      <div className="updocs__container">
        <h2 style={{ textAlign: "center" }}>
          FORMULAR ONLINE - INSCRIERE CONCURS DE ADMITERE 2024
          <br />
          UNIVERSITATEA ECOLOGICA DIN BUCURESTI
        </h2>
        <h3 style={{ textAlign: "center" }}>ATASATI URMATOARELE DOCUMENTE</h3>
        <p>
          (<span style={{ color: "red" }}>*</span> informație necesară, opt -
          informație opțională)
        </p>
        <div className="updocs__maincontainer">
          <div className="form-uploadDoc">
            <div className="form-group-uploaddoc">
              <div className="updocs__labelinput">
                <label htmlFor="image" className="updocs__label">
                  FOTOGRAFIE (poza), color, recentă{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <ImageUpload
                  id="imagine"
                  onInput={inputHandler}
                  val={formState.inputs.imagine.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="diplomabac" className="updocs__label">
                  <p>
                    INCARCA DIPLOMA DE BACALAUREAT/ADEVERINTA PENTRU PROMOTIA
                    CURENTA 2024{" "}
                    <span style={{ color: "red" }}>
                      * (documentul scanat trebuie să conțină toate paginile,
                      inclusiv față-verso, fisier PDF sau DOC)
                    </span>
                  </p>
                </label>
                <FileUploadOnlyPdf
                  buttonText="INCARCA"
                  id="diplomabac"
                  onInput={inputHandler}
                  val={formState.inputs.diplomabac.value}
                />
              </div>
              <div className="updocs__labelinput">
                <label htmlFor="foaiematricola" className="updocs__label">
                  INCARCA FOAIA MATRICOLA (daca Diploma de Bacalaureat a fost
                  însoțită de aceasta) (opt) (documentul scanat trebuie să
                  conțină toate paginile, inclusiv față-verso, fisier PDF sau
                  DOC)
                </label>
                <FileUploadOnlyPdf
                  buttonText="INCARCA"
                  id="foaiematricola"
                  onInput={inputHandler}
                  val={formState.inputs.foaiematricola.value}
                />
              </div>
              {/* only for master */}
              {selectieUser && selectieUser.includes("master") && (
                <>
                  <div className="updocs__labelinput">
                    <label htmlFor="diplomalicenta" className="updocs__label">
                      DIPLOMA DE LICENTA/ADEVERINTA PENTRU PROMOTIA
                      CURENTA(2024){" "}
                      <span style={{ color: "red" }}>
                        * (documentul scanat trebuie să conțină toate paginile,
                        inclusiv față-verso, fisier PDF sau DOC)
                      </span>
                    </label>
                    <FileUploadOnlyPdf
                      buttonText="INCARCA"
                      id="diplomalicenta"
                      onInput={inputHandler}
                      val={formState.inputs.diplomalicenta.value}
                    />
                  </div>
                  <div className="updocs__labelinput">
                    <label htmlFor="suplimentdiploma" className="updocs__label">
                      SUPLIMENTUL LA DIPLOMA SAU FOAIA MATRICOLA{" "}
                      <span style={{ color: "red" }}>
                        * (documentul scanat trebuie să conțină toate paginile,
                        inclusiv față-verso, fisier PDF sau DOC)
                      </span>
                    </label>
                    <FileUploadOnlyPdf
                      buttonText="INCARCA"
                      id="suplimentdiploma"
                      onInput={inputHandler}
                      val={formState.inputs.suplimentdiploma.value}
                    />
                  </div>
                </>
              )}

              <div className="updocs__labelinput">
                <label htmlFor="certificatnastere" className="updocs__label">
                  INCARCA CERTIFICATUL DE NASTERE{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="certificatnastere"
                  onInput={inputHandler}
                  val={formState.inputs.certificatnastere.value}
                />
              </div>
              <div className="updocs__labelinput">
                <label htmlFor="certificatcasatorie" className="updocs__label">
                  CERTIFICAT DE CASATORIE/ACTUL ADMINISTRATIV (în cazul
                  schimbării numelui) (opt)
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="certificatcasatorie"
                  onInput={inputHandler}
                  val={formState.inputs.certificatcasatorie.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="carteidentitate" className="updocs__label">
                  CARTE DE IDENTITATE <span style={{ color: "red" }}>*</span>
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="carteidentitate"
                  onInput={inputHandler}
                  val={formState.inputs.carteidentitate.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="adeverintamedicala" className="updocs__label">
                  ADEVERINTA MEDICALA (din care să rezulte că este sunteți apt
                  să urmați studii superioare, iar pentru Facultatea de Educație
                  Fizică și Sport în adeverință se menționează "apt pentru efort
                  fizic") <span style={{ color: "red" }}>*</span>
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="adeverintamedicala"
                  onInput={inputHandler}
                  val={formState.inputs.adeverintamedicala.value}
                />
              </div>
              <div className="updocs__labelinput">
                <div>
                  <input
                    style={{ zoom: "1.4" }}
                    type="radio"
                    name="dovadaplataradiobtn"
                    id="platacuchitanta"
                    onClick={plataCuChitanta}
                    checked={pCuChitanta}
                  />
                  <label
                    style={{ fontSize: "22px" }}
                    htmlFor="platacuchitanta"
                    className="updocs__label"
                  >
                    Am platit, incarc chitanta acum
                  </label>
                </div>
                {valueChitanta === "sters" && (
                  <div>
                    <input
                      style={{ zoom: "1.4" }}
                      type="radio"
                      name="dovadaplataradiobtn"
                      id="platacucardonline"
                      onClick={plataCuCard}
                      checked={pCuCard}
                    />
                    <label
                      style={{ fontSize: "22px" }}
                      htmlFor="platacucardonline"
                      className="updocs__label"
                    >
                      Platesc online cu cardul dupa ce trimit formularul
                    </label>
                  </div>
                )}
              </div>

              {pCuChitanta && (
                <div className="updocs__labelinput">
                  <label htmlFor="chitantaadmitere" className="updocs__label">
                    CHITANTA TAXA DE INSCRIERE
                  </label>
                  <FileUpload
                    buttonText="INCARCA"
                    id="chitantaadmitere"
                    onInput={inputHandler}
                    chitanta={valueChitanta}
                    setChitanta={setChitanta}
                    val={formState.inputs.chitantaadmitere.value}
                  />
                </div>
              )}
            </div>
            <div style={{ padding: "10px 60px" }}>
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                CONSIMTAMANT
              </p>
              <p
                style={{
                  padding: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                1. Documentele încărcate de mine în această cerere online de
                înscriere la admitere vor fi prezentate în original şi copie pe
                suport de hârtie, pentru certificarea conformității acestora.
                Certificarea conformității va fi realizată de un reprezentant al
                universității la primirea dosarului de concurs, conform
                prevederilor art. 2 alin. 3 din OUG nr. 41/2016. Se va reține in
                original doar diploma de bacalaureat.
              </p>
              <p
                style={{
                  padding: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                2. Candidaţii admişi au obligaţia, ca în termen de 5 zile
                lucrătoare de la afișarea rezultatelor finale, să prezinte toate
                documentele încarcate prin prezenta cerere; să achite prima rată
                și să semneze contractul de școlarizare. Nerespectarea acestui
                termen sau neconformitatea documentelor încărcate online cu cele
                originale atrage pierderea calităţii de admis. Am luat la
                cunoștiință, că în cazul retragerii din universitate taxa
                achitată nu se restituie.
              </p>
              <p
                style={{
                  padding: "5px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                3. Cunoscând prevederile art.326 Cod penal (privind falsul în
                declarații), declar pe propria răspundere că actele încărcate
                prin prezenta cerere online la înscriere există în original și
                sunt autentice.
              </p>
              <div style={{ textAlign: "center" }}>
                <label
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    fontSize: "20px",
                  }}
                  htmlFor="consimtamant"
                >
                  <input
                    style={{
                      zoom: "1.5",
                      display: "inline-block",
                      verticalAlign: "top",
                    }}
                    id="consimtamant"
                    type="checkbox"
                    name="consimtamant"
                    onChange={inputHandlerCheckbox}
                    checked={checked}
                  />
                  Sunt de acord cu toate cele 3 puncte de mai sus cu privire la
                  Consimtâmânt.
                </label>
              </div>
            </div>
            <div style={{ color: "red" }}>
              <p>
                Daca butonul (Trimite formularul) este inactiv (gri) va rugam
                verificati urmatoarele:
              </p>
              <p style={{ textAlign: "center" }}>1. Daca ati bifat la Pas1</p>
              <p style={{ textAlign: "center" }}>
                2. Daca ati completat tot ce era necesar la Pas 2
              </p>
              <p style={{ textAlign: "center" }}>
                3. Daca ati incarcat toate fisierele necesare la Pas 3
              </p>
              <br />
              <p style={{ textAlign: "center" }}>
                Pentru dificultati tehnice apelati la suport prin WhatsApp 0741
                038 535
              </p>
            </div>
            <div className="form__containerButon">
              {/* checked &&
                    formState.isValid === true &&
                    selectieUser !== "" */}
              <Button
                disabled={
                  !(
                    checked &&
                    formState.isValid === true &&
                    selectieUser !== "" &&
                    (formState.inputs.chitantaadmitere.value !== "" || pCuCard)
                  )
                }
                onClick={() => go("submit")}
              >
                TRIMITE FORMULARUL
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocuments;

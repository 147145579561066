import React from "react";
import "./Navigator.css";

const Navigator = ({ navigation, index }) => {
  const { go } = navigation;

  return (
    <div className="navigator__container">
      <button
        onClick={() => go("facultati")}
        className={
          index === 0
            ? "buttonCustomNavigator buttonActive"
            : "buttonCustomNavigator"
        }
      >
        Pasul 1
      </button>

      <button
        onClick={() => go("formular")}
        className={
          index === 1
            ? "buttonCustomNavigator buttonActive"
            : "buttonCustomNavigator"
        }
      >
        Pasul 2
      </button>

      <button
        onClick={() => go("documents")}
        className={
          index === 2
            ? "buttonCustomNavigator buttonActive"
            : "buttonCustomNavigator"
        }
      >
        Pasul 3
      </button>
    </div>
  );
};

export default Navigator;

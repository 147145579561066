import React from "react";

import Input from "../../shared/components/FormElements/Input";

import Button from "../../shared/components/FormElements/Button";

import Form from "../../shared/components/UIElements/Form";

import { VALIDATOR_REQUIRE } from "../../shared/util/validators";

import "./Formular.css";

const Formular = ({ selectieUser, formState, inputHandler, navigation }) => {
  const { next } = navigation;

  return (
    <>
      <div className="form__container">
        <h2 style={{ textAlign: "center" }}>
          FORMULAR ONLINE - INSCRIERE CONCURS DE ADMITERE 2024
          <br />
          UNIVERSITATEA ECOLOGICA DIN BUCURESTI
        </h2>
        <h3 style={{ textAlign: "center" }}>DATE DE IDENTIFICARE</h3>

        <p>
          (<span style={{ color: "red" }}>*</span> informație necesară, opt -
          informație opțională)
        </p>
        <Form className="form">
          <div className="form-group">
            {/* names */}
            <div className="form-group-names">
              <Input
                id="firstActualName"
                label="NUMELE DE FAMILIE ACTUAL: "
                element="input"
                type="text"
                placeholder="Numele de familie actual"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti numele de familie actual"
                onInput={inputHandler}
                initialValue={formState.inputs.firstActualName.value}
                initialValid={
                  formState.inputs.firstActualName.value ? true : false
                }
                //value={formState.inputs.firstActualName.value}
              />
              <Input
                id="firstBornName"
                label="NUMELE DE FAMILIE LA NASTERE: "
                element="input"
                type="text"
                placeholder="Numele de familie la nastere"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti numele de familie de la nastere"
                onInput={inputHandler}
                initialValue={formState.inputs.firstBornName.value}
                initialValid={
                  formState.inputs.firstBornName.value ? true : false
                }
                //value={formState.inputs.firstBornName.value}
              />
            </div>
            <Input
              id="lastName"
              label="PRENUME: "
              element="input"
              type="text"
              placeholder="PRENUME"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti prenumele"
              onInput={inputHandler}
              initialValue={formState.inputs.lastName.value}
              initialValid={formState.inputs.lastName.value ? true : false}
              //value={formState.inputs.lastName.value}
            />

            <Input
              id="fatherInitial"
              label="INITIALA TATALUI: "
              element="input"
              type="text"
              placeholder="INITIALA TATALUI"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti initiala tatalui"
              onInput={inputHandler}
              initialValue={formState.inputs.fatherInitial.value}
              initialValid={formState.inputs.fatherInitial.value ? true : false}
              //value={formState.inputs.fatherInitial.value}
            />

            <div className="form-group-names">
              <Input
                id="cnp"
                label="CNP: "
                element="input"
                type="text"
                placeholder="CNP"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti CNP-ul"
                onInput={inputHandler}
                initialValue={formState.inputs.cnp.value}
                initialValid={formState.inputs.cnp.value ? true : false}
                //value={formState.inputs.cnp.value}
              />

              <Input
                id="bornDate"
                label="DATA NASTERII(zz/ll/aaaa): "
                element="input"
                type="text"
                placeholder="DATA NASTERII(zz/ll/aaaa)"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti data nasterii"
                onInput={inputHandler}
                initialValue={formState.inputs.bornDate.value}
                initialValid={formState.inputs.bornDate.value ? true : false}
                //value={formState.inputs.bornDate.value}
              />
            </div>
            <Input
              id="bornPlace"
              label="LOCALITATEA NASTERII: "
              element="input"
              type="text"
              placeholder="LOCALITATEA NASTERII"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti localitatea nasterii"
              onInput={inputHandler}
              initialValue={formState.inputs.bornPlace.value}
              initialValid={formState.inputs.bornPlace.value ? true : false}
              //value={formState.inputs.bornPlace.value}
            />
            <Input
              id="bornDistrict"
              label="JUDETUL NASTERII: "
              element="input"
              type="text"
              placeholder="JUDETUL NASTERII"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti judetul nasterii"
              onInput={inputHandler}
              initialValue={formState.inputs.bornDistrict.value}
              initialValid={formState.inputs.bornDistrict.value ? true : false}
              //value={formState.inputs.bornDistrict.value}
            />
            <Input
              id="bornCountry"
              label="TARA NASTERII: "
              element="input"
              type="text"
              placeholder="TARA NASTERII"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti tara nasterii"
              onInput={inputHandler}
              initialValue={formState.inputs.bornCountry.value}
              initialValid={formState.inputs.bornCountry.value ? true : false}
              //value={formState.inputs.bornCountry.value}
            />
            <br />
            <div style={{ paddingLeft: "18px", fontWeight: "bold" }}>
              CARTE DE IDENTITATE:*
            </div>
            <div className="form-group-names">
              {/* <Input
                    id="identityCart"
                    label="CARTE IDENTITATE: *"
                    element="input"
                    type="text"
                    placeholder="CARTE IDENTITATE"
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Introduceti CARTE IDENTITATE"
                    onInput={inputHandler}
                />  */}
              <Input
                id="seriesCart"
                label="seria: "
                element="input"
                type="text"
                placeholder="seria"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti seria"
                onInput={inputHandler}
                initialValue={formState.inputs.seriesCart.value}
                initialValid={formState.inputs.seriesCart.value ? true : false}
                //value={formState.inputs.seriesCart.value}
              />
              <Input
                id="numberCart"
                label="numarul: "
                element="input"
                type="text"
                placeholder="numarul"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti numarul"
                onInput={inputHandler}
                initialValue={formState.inputs.numberCart.value}
                initialValid={formState.inputs.numberCart.value ? true : false}
                //value={formState.inputs.numberCart.value}
              />
              <Input
                id="setCart"
                label="eliberat de: "
                element="input"
                type="text"
                placeholder="eliberat de"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti eliberat de"
                onInput={inputHandler}
                initialValue={formState.inputs.setCart.value}
                initialValid={formState.inputs.setCart.value ? true : false}
                //value={formState.inputs.setCart.value}
              />
              <Input
                id="releaseDateCart"
                label="data eliberarii: "
                element="input"
                type="text"
                placeholder="data eliberarii"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti data eliberarii"
                onInput={inputHandler}
                initialValue={formState.inputs.releaseDateCart.value}
                initialValid={
                  formState.inputs.releaseDateCart.value ? true : false
                }
                //value={formState.inputs.releaseDateCart.value}
              />
            </div>

            <Input
              id="currentCity"
              label="LOCALITATEA DE DOMICILIU: "
              element="input"
              type="text"
              placeholder="LOCALITATEA DE DOMICILIU"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Introduceti LOCALITATEA DE DOMICILIU"
              onInput={inputHandler}
              initialValue={formState.inputs.currentCity.value}
              initialValid={formState.inputs.currentCity.value ? true : false}
              //value={formState.inputs.currentCity.value}
            />

            <div className="form-group-names">
              <Input
                id="currentDistrict"
                label="JUDETUL DE DOMICILIU: "
                element="input"
                type="text"
                placeholder="JUDETUL DE DOMICILIU"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti judetul de domiciliu"
                onInput={inputHandler}
                initialValue={formState.inputs.currentDistrict.value}
                initialValid={
                  formState.inputs.currentDistrict.value ? true : false
                }
                //value={formState.inputs.currentDistrict.value}
              />
              <Input
                id="currentCountry"
                label="TARA DE DOMICILIU: "
                element="input"
                type="text"
                placeholder="TARA DE DOMICILIU"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti tara de domiciliu"
                onInput={inputHandler}
                initialValue={formState.inputs.currentCountry.value}
                initialValid={
                  formState.inputs.currentCountry.value ? true : false
                }
                //value={formState.inputs.currentCountry.value}
              />
            </div>
            <div className="form-group-names">
              <Input
                id="phone"
                label="TELEFON: "
                element="input"
                type="text"
                placeholder="TELEFON"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti telefon"
                onInput={inputHandler}
                initialValue={formState.inputs.phone.value}
                initialValid={formState.inputs.phone.value ? true : false}
                //value={formState.inputs.phone.value}
              />
              <Input
                id="email"
                label="ADRESA DE EMAIL: "
                element="input"
                type="text"
                placeholder="ADRESA DE EMAIL"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Introduceti adresa de mail"
                onInput={inputHandler}
                initialValue={formState.inputs.email.value}
                initialValid={formState.inputs.email.value ? true : false}
                //value={formState.inputs.email.value}
              />
            </div>
            <div className="form-group-names">
              {selectieUser && selectieUser.includes("licenta") && (
                <Input
                  id="mediabac"
                  label="MEDIA EXAMENULUI DE BACALAUREAT: "
                  element="input"
                  type="text"
                  placeholder="MEDIA DE BACALAUREAT"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Introduceti media examenului de bacalaureat"
                  onInput={inputHandler}
                  initialValue={formState.inputs.mediabac.value}
                  initialValid={formState.inputs.mediabac.value ? true : false}
                  //value={formState.inputs.mediabac.value}
                />
              )}
              {selectieUser && selectieUser.includes("master") && (
                <Input
                  id="medialicenta"
                  label="MEDIA EXAMENULUI DE LICENTA/DIPLOMA: "
                  element="input"
                  type="text"
                  placeholder="MEDIA LICENTA SAU DIPLOMA"
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Introduceti media examenului de licenta/diploma"
                  onInput={inputHandler}
                  initialValue={formState.inputs.medialicenta.value}
                  initialValid={
                    formState.inputs.medialicenta.value ? true : false
                  }
                  //value={formState.inputs.medialicenta.value}
                />
              )}
            </div>
          </div>
          <div className="form__containerButon">
            <Button onClick={next}>PASUL 3</Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Formular;

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../../shared/components/UIElements/Modal";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../shared/components/Context/auth-context";
import "./UserDetailsPage.css";

const UserDetailsPage = (props) => {
  const [loadedUser, setLoadedUser] = useState();
  const [isLoadingUser, setIsLoadingUser] = useState();
  const [loadedValidate, setLoadedValidate] = useState();
  const [isLoadingValidate, setIsLoadingValidate] = useState();
  const [loadedEmail, setLoadedEmail] = useState();
  const [isLoadingSendingEmail, setIsLoadingSendingEmail] = useState();
  const [textareaValue, setTextareaValue] = useState();
  //surePayment
  const [valueSurePayment, setValueSurePayment] = useState();
  const [loadedSurePayment, setLoadedSurePayment] = useState();
  //
  const [error, setError] = useState();
  const auth = useContext(AuthContext);
  //const history = useHistory();
  //console.log(auth);

  useEffect(() => {
    setIsLoadingUser(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/${props.userId}`
        );
        const responseData = await response.json();
        //console.log(responseData.data);
        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        //console.log(responseData);
        setLoadedUser(responseData.data);
      } catch (err) {
        setIsLoadingUser(false);
        setError(err.message);
      }
      //console.log(loadedMenuReviews);
      setIsLoadingUser(false);
    };

    sendRequest();
  }, [setIsLoadingUser, props.userId]);

  const errorHandler = () => {
    setError(null);
  };

  const validateUser = () => {
    //console.log(auth);
    setIsLoadingValidate(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/validate",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              adminName: auth.userName,
              userId: loadedUser._id,
              userEmail: loadedUser.email,
            }),
          }
        );

        const responseData = await response.json();
        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setLoadedValidate(responseData.name);
        closeShowSuccesHandler();
      } catch (err) {
        setIsLoadingValidate(false);
        setError(err.message);
      }
      setIsLoadingValidate(false);
    };
    sendRequest();

    //history.go();
  };

  // Show modal form const

  const [showSuccess, setShowSuccess] = useState(false);

  const openShowSuccesHandler = () => setShowSuccess(true);

  const closeShowSuccesHandler = () => setShowSuccess(false);

  //Modal after email was sent
  const [showSuccessEmail, setShowSuccessEmail] = useState(false);

  const openShowSuccesEmailHandler = () => setShowSuccessEmail(true);

  const closeShowSuccesEmailHandler = () => {
    setTextareaValue();
    setShowSuccessEmail(false);
  };

  const sendEmailRectificari = (event) => {
    event.preventDefault();
    setIsLoadingSendingEmail(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/sendEmailRectificari",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailMessage: textareaValue,
              userId: loadedUser._id,
              userEmail: loadedUser.email,
            }),
          }
        );

        const responseData = await response.json();
        //console.log(responseData.data);
        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setLoadedEmail(responseData.userEmail);
        openShowSuccesEmailHandler();
        //console.log("res",responseData.adminName);
      } catch (err) {
        setIsLoadingSendingEmail(false);
        setError(err.message);
      }
      //console.log(loadedMenuReviews);
      setIsLoadingSendingEmail(false);
    };
    sendRequest();
  };

  const handleChangeTextarea = (event) => {
    setTextareaValue(event.target.value.replace(/\n/gim, "<br/>"));
  };

  const handleChangeSurePayment = (event) => {
    setValueSurePayment(event.target.value);
  };

  const saveSurePayment = () => {
    const sendRequest = async () => {
      try {
        const response = await fetch(
          "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/saveSurePayment",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              valueSurePayment: valueSurePayment,
              userId: loadedUser._id,
            }),
          }
        );

        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setLoadedSurePayment(responseData.surePayment);
        openShowSuccesSurePaymentHandler();
      } catch (err) {
        setError(err.message);
      }
    };
    sendRequest();
  };

  //Modal after surePayment
  const [showSuccessSurePayment, setShowSuccessSurePayment] = useState(false);

  const openShowSuccesSurePaymentHandler = () =>
    setShowSuccessSurePayment(true);

  const closeShowSuccesSurePayment = () => {
    loadedUser.platacucardul = valueSurePayment;
    setValueSurePayment("");
    setShowSuccessSurePayment(false);
  };

  return (
    <>
      <Modal
        show={showSuccess}
        // onCancel={closeShowSuccessHandler}

        header={"Success"}
        contentClass=""
        footerClass=""
      >
        <p>Confirmati operatiunea de VALIDARE?</p>

        <footer>
          <Button className="btn" onClick={validateUser}>
            DA
          </Button>
          <Button className="btn" onClick={closeShowSuccesHandler}>
            NU
          </Button>
        </footer>
      </Modal>

      <Modal
        show={showSuccessEmail}
        header={"Success"}
        contentClass=""
        footerClass=""
      >
        <p>Emailul a fost trimis catre candidatul cu email: {loadedEmail}</p>
        <footer>
          <Button className="btn" onClick={closeShowSuccesEmailHandler}>
            OK
          </Button>
        </footer>
      </Modal>

      <Modal
        show={showSuccessSurePayment}
        header={"Success"}
        contentClass=""
        footerClass=""
      >
        <p>{loadedSurePayment}</p>
        <footer>
          <Button className="btn" onClick={closeShowSuccesSurePayment}>
            OK
          </Button>
        </footer>
      </Modal>

      <ErrorModal error={error} onClear={errorHandler} />
      {!isLoadingUser && loadedUser && (
        <>
          <div className="text__container">
            <h3 style={{ textAlign: "center" }}>DATE DE IDENTIFICARE</h3>
            {/* names */}
            <div className="form-group-names">
              <div className="text__label">
                NUMELE DE FAMILIE ACTUAL:
                <div className="text__data">{loadedUser.firstActualName}</div>
              </div>
              <div className="text__label">
                NUMELE DE FAMILIE LA NASTERE:
                <div className="text__data">{loadedUser.firstBornName}</div>
              </div>
            </div>

            <div className="text__label">
              PRENUME:
              <span className="text__data--inline">{loadedUser.lastName}</span>
            </div>

            <div className="text__label">
              INITIALA TATALUI:
              <span className="text__data--inline">
                {loadedUser.fatherInitial}
              </span>
            </div>

            <div className="form-group-names">
              <div className="text__label">
                CNP:
                <div className="text__data">{loadedUser.cnp}</div>
              </div>

              <div className="text__label">
                DATA NASTERII:
                <div className="text__data">{loadedUser.bornDate}</div>
              </div>
            </div>

            <div className="text__label">
              LOCALITATEA NASTERII:
              <span className="text__data--inline">{loadedUser.bornPlace}</span>
            </div>

            <div className="text__label">
              JUDETUL NASTERII:
              <span className="text__data--inline">
                {loadedUser.bornDistrict}
              </span>
            </div>

            <div className="text__label">
              TARA NASTERII:
              <span className="text__data--inline">
                {loadedUser.bornCountry}
              </span>
            </div>

            <br />
            <div style={{ paddingLeft: "18px", fontWeight: "bold" }}>
              CARTE DE IDENTITATE:
            </div>

            <div className="text__label">
              seria:
              <span className="text__data--inline">
                {loadedUser.seriesCart}
              </span>
            </div>

            <div className="text__label">
              numarul:
              <span className="text__data--inline">
                {loadedUser.numberCart}
              </span>
            </div>

            <div className="text__label">
              eliberat de:
              <span className="text__data--inline">{loadedUser.setCart}</span>
            </div>

            <div className="text__label">
              data eliberarii:
              <span className="text__data--inline">
                {loadedUser.releaseDateCart}
              </span>
            </div>

            <div className="text__label">
              LOCALITATEA DE DOMICILIU:
              <span className="text__data--inline">
                {loadedUser.currentCity}
              </span>
            </div>

            <div className="text__label">
              JUDETUL DE DOMICILIU:
              <span className="text__data--inline">
                {loadedUser.currentDistrict}
              </span>
            </div>

            <div className="text__label">
              TARA DE DOMICILIU:
              <span className="text__data--inline">
                {loadedUser.currentCountry}
              </span>
            </div>

            <div className="form-group-names">
              <div className="text__label">
                TELEFON:<div className="text__data">{loadedUser.phone}</div>
              </div>

              <div className="text__label">
                ADRESA DE EMAIL:
                <div className="text__data">{loadedUser.email}</div>
              </div>
            </div>
            <div className="form-group-names">
              <div className="text__label">
                MEDIA EXAMENULUI DE BACALAUREAT:
                <div className="text__data">{loadedUser.mediabac}</div>
              </div>

              <div className="text__label">
                MEDIA EXAMENULUI DE LICENTA/DIPLOMA:
                <div className="text__data">{loadedUser.medialicenta}</div>
              </div>
            </div>
            <div
              style={{
                border: "1px gray solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div className="text__label">
                PLATA:
                <span className="text__data--inline">
                  {loadedUser.platacucardul}
                  <br />
                  Modifică:
                  <input
                    type="text"
                    style={{
                      fontSize: "20px",
                      width: "480px",
                      marginRight: "10px",
                    }}
                    value={valueSurePayment}
                    onChange={handleChangeSurePayment}
                  />
                  <Button onClick={saveSurePayment}>Salveaza plata</Button>
                </span>
              </div>
            </div>

            <h3 style={{ textAlign: "center" }}>DOCUMENTE ATASATE</h3>
            <div className="text__label">
              FOTOGRAFIE:{" "}
              <a
                className="text__a--link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.imagine}`}
              >
                Arata
              </a>
            </div>
            <hr />
            <div className="text__label">
              DIPLOMA DE BACALAUREAT/ADEVERINTA:{" "}
              <a
                className="text__a--link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.diplomabac}`}
              >
                Arata
              </a>
            </div>
            <hr />
            {loadedUser.foaiematricola !== "" && (
              <>
                <div className="text__label">
                  FOAIA MATRICOLA:{" "}
                  <a
                    className="text__a--link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.foaiematricola}`}
                  >
                    Arata
                  </a>
                </div>
                <hr />
              </>
            )}
            {loadedUser.diplomalicenta !== "" && (
              <>
                <div className="text__label">
                  DIPLOMA DE LICENTA:{" "}
                  <a
                    className="text__a--link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.diplomalicenta}`}
                  >
                    Arata
                  </a>
                </div>
                <hr />
              </>
            )}

            {loadedUser.suplimentdiploma !== "" && (
              <>
                <div className="text__label">
                  SUPLIMENT DIPLOMA:{" "}
                  <a
                    className="text__a--link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.suplimentdiploma}`}
                  >
                    Arata
                  </a>
                </div>
                <hr />
              </>
            )}

            <div className="text__label">
              CERTIFICAT DE NASTERE:{" "}
              <a
                className="text__a--link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.certificatnastere}`}
              >
                Arata
              </a>
            </div>
            <hr />
            {loadedUser.certificatcasatorie !== "" && (
              <>
                <div className="text__label">
                  CERTIFICAT DE CASATORIE:{" "}
                  <a
                    className="text__a--link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.certificatcasatorie}`}
                  >
                    Arata
                  </a>
                </div>
                <hr />
              </>
            )}
            <div className="text__label">
              CERTIFICAT DE IDENTITATE:{" "}
              <a
                className="text__a--link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.carteidentitate}`}
              >
                Arata
              </a>
            </div>
            <hr />
            <div className="text__label">
              ADEVERINTA MEDICALA:{" "}
              <a
                className="text__a--link"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.adeverintamedicala}`}
              >
                Arata
              </a>
            </div>
            <hr />
            {loadedUser.chitantaadmitere !== "" &&
              loadedUser.chitantaadmitere !== undefined && (
                <>
                  <div className="text__label">
                    DOVADA PLATA:{" "}
                    <a
                      className="text__a--link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/${loadedUser.chitantaadmitere}`}
                    >
                      Arata
                    </a>
                  </div>
                  <hr />
                </>
              )}
            {loadedUser.verifiedBy !== undefined &&
              loadedUser.verifiedBy !== "" && (
                <div className="validateUser">
                  <div>
                    VALIDAT DE:{"  "}
                    {loadedUser.verifiedBy}
                    <span>
                      <i class="fas fa-check"></i>
                    </span>
                  </div>
                </div>
              )}
            {loadedValidate && (
              <div className="validateUser">
                <div>
                  VALIDAT DE:{"  "}
                  {loadedValidate}
                  <span>
                    <i class="fas fa-check"></i>
                  </span>
                </div>
              </div>
            )}
            <hr />
            <div className="form__containerButon">
              <Button type="button" onClick={openShowSuccesHandler}>
                VALIDEAZA si TRIMITE EMAIL
              </Button>
            </div>
            <hr />

            <div>
              <h3>RECTIFICARI</h3>
              <p>
                (pentru trecerea la un rand nou apasati tasta enter, care va
                pune "&lt;br/&gt;")
              </p>
              <form id="customForm" onSubmit={sendEmailRectificari}>
                <div>
                  <textarea
                    value={textareaValue}
                    onChange={handleChangeTextarea}
                    rows="15"
                    cols="90"
                    form="customForm"
                    name="textareaEmail"
                  ></textarea>
                </div>
                <div>
                  <Button type="submit">TRIMITE EMAIL RECTIFICARI</Button>
                </div>
              </form>
            </div>
            <hr />

            <div></div>
          </div>
        </>
      )}
    </>
  );
};

export default UserDetailsPage;

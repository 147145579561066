import React, { useRef, useState, useEffect } from 'react';

import Button from './Button';
import './FileUpload.css';

const FileUpload = props => {
  const [file, setFile] = useState();
  const [isValid, setIsValid] = useState(false);


  useEffect(() => {
    setFile(props.val);
    //console.log(file);
  }, [props.val, setFile]);

  const filePickerRef = useRef();

  const pickedHandler = event => {
    let pickedFile;
    let fileIsValid = isValid;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      //setFile(pickedFile.name);
      if (props.chitanta) {
        props.setChitanta("setat");
      }


      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }
    props.onInput(props.id, pickedFile, fileIsValid);
  };

  const deleteFile = event => {
    if (file) {
      setFile();
      if (props.chitanta) {
        props.setChitanta("sters");
        props.onInput(props.id, null, true);
      } else {
        props.onInput(props.id, null, false);
      }
    } else {
      //console.log("notdeleteFile");
    }
  }

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  return (
    <div className='form-control-fileupload'>
      <input
        disabled={file}
        id={props.id}
        ref={filePickerRef}
        style={{ display: 'none' }}
        type='file'
        accept='.pdf,.docx,.jpeg,.doc,.jpg'
        onChange={pickedHandler}
      /* imageUrl={props.imageUrl} */
      />
      <div className={`image-upload ${props.center && 'center'}`}>
        <div className='file-upload__preview'>
          {file ? <><p>{file.name}</p><p style={{ color: 'green', fontWeight: 'bold', textAlign: 'center' }}>INCARCAT CU SUCCES</p></> : <p style={{ color: 'red', fontWeight: 'bold', textAlign: 'center' }}>FISIERUL NU ESTE INCARCAT</p>}
        </div>
        <Button type='button' disabled={file} onClick={pickImageHandler}>
          {props.buttonText}
        </Button>
        <Button type='button' danger disabled={!file} onClick={deleteFile}>
          <i className="fas fa-trash-alt"></i>
        </Button>
      </div>
      {!isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default FileUpload;

import React, { useEffect, useState } from "react";

import Button from "../../shared/components/FormElements/Button";
import Form from "../../shared/components/UIElements/Form";

import "./ListaFacultati.css";

const ListaFacultati = ({
  selectieUser,
  clickedRadio,
  clickedRadioLicentaMaster,
  clickedRadioFrecvNon,
  clickedRadioStudii,
  clickedRadioStudiiMaster,
  radioHandler,
  radioHandlerLicentaMaster,
  radioHandlerFrecvNon,
  radioHandlerStudii,
  radioHandlerStudiiMaster,
  navigation,
}) => {
  const { next } = navigation;

  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [facultati, setFacultati] = useState();
  console.log("facultati :", facultati);

  useEffect(() => {
    setIsLoading(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          //`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/superAdmin/getActiveChecks`
          `https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/superAdmin/getActiveChecks`
        );
        const responseData = await response.json();
        console.log("data facultati: ", responseData.data);

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setFacultati(responseData.data);
      } catch (err) {
        setIsLoading(false);
        setError(err.message || "Probleme la fetch facultati");
      }
      //console.log(loadedMenuReviews);
      setIsLoading(false);
    };

    sendRequest();
  }, []);

  return (
    <>
      {error && <div>{error}</div>}
      {isLoading && <div>Loading...</div>}
      {!isLoading && facultati && (
        <div className="facultati__container">
          <h2 style={{ textAlign: "center" }}>
            FORMULAR ONLINE - INSCRIERE CONCURS DE ADMITERE 2024
            <br />
            UNIVERSITATEA ECOLOGICA DIN BUCURESTI
          </h2>
          <div style={{ padding: "10px 40px" }}>
            <p
              style={{
                padding: "5px",
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                border: "1px gray solid",
                borderRadius: "5px",
              }}
            >
              Acest formular online reprezintă Cererea dvs. de înscriere la
              concursul de admitere 2024 pentru un program de studii
              universitare de licență sau master. Prin declararea adresei de
              email și a unui număr de telefon, ambele valide, vă exprimați
              consimtământul să fiți contactat de un reprezentant al
              universității în vederea confirmării înscrierii.
            </p>
          </div>
          <h3 style={{ textAlign: "center" }}>
            Doresc sa ma inscriu la examenul de admitere la:
          </h3>

          <Form className="form">
            <div className="form-group">
              <ul className="facultati__ul">
                {/* Facultatea de Drept */}
                {!(
                  parseInt(facultati[0].uncheckedCountLicenta) ===
                    parseInt(facultati[0].licenta.length) &&
                  parseInt(facultati[0].uncheckedCountMaster) ===
                    parseInt(facultati[0].master.length)
                ) && (
                  <li className="facultati__li">
                    <label className="facultati__label color1" htmlFor="drept">
                      <input
                        id="drept"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        checked={clickedRadio === "drept"}
                        onChange={(e) => radioHandler("drept")}
                      />
                      Facultatea de Drept
                    </label>
                  </li>
                )}

                {clickedRadio === "drept" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[0].uncheckedCountLicenta) <
                        parseInt(facultati[0].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="dreptlicenta"
                          >
                            <input
                              id="dreptlicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster === "dreptlicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster("dreptlicenta")
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}

                      {parseInt(facultati[0].uncheckedCountMaster) <
                        parseInt(facultati[0].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="dreptmaster"
                          >
                            <input
                              id="dreptmaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster === "dreptmaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster("dreptmaster")
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster === "dreptlicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          {facultati[0].licenta[0].checked === true && (
                            <div className="facultati__ifLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="fac-drept--licenta--studiu-drept--if"
                              >
                                <input
                                  id="fac-drept--licenta--studiu-drept--if"
                                  className="facultati__input studiu_input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de drept,licenta,if,drept,"
                                  checked={
                                    clickedRadioStudii ===
                                    "fac-drept--licenta--studiu-drept--if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "fac-drept--licenta--studiu-drept--if"
                                    )
                                  }
                                />
                                Drept(IF-învățământ cu frecvență)
                              </label>
                            </div>
                          )}

                          {facultati[0].licenta[1].checked === true && (
                            <div className="facultati__ifrLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="fac-drept--licenta--studiu-drept--ifr"
                              >
                                <input
                                  id="fac-drept--licenta--studiu-drept--ifr"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de drept,licenta,ifr,drept"
                                  checked={
                                    clickedRadioStudii ===
                                    "fac-drept--licenta--studiu-drept--ifr"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "fac-drept--licenta--studiu-drept--ifr"
                                    )
                                  }
                                />
                                Drept(IFR-învățământ cu frecvență redusă)
                              </label>
                            </div>
                          )}
                          {facultati[0].licenta[2].checked === true && (
                            <div className="facultati__ifrLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="fac-drept--licenta--studiu-drept--id"
                              >
                                <input
                                  id="fac-drept--licenta--studiu-drept--id"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de drept,licenta,id,drept"
                                  checked={
                                    clickedRadioStudii ===
                                    "fac-drept--licenta--studiu-drept--id"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "fac-drept--licenta--studiu-drept--id"
                                    )
                                  }
                                />
                                Drept(ID-Învățământ la distanță)
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster === "dreptmaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[0].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster1if"
                              >
                                <input
                                  id="studiidreptmaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Dreptul inteligentei artificiale"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster1if"
                                    )
                                  }
                                />
                                Dreptul inteligentei artificiale (IF-învățământ
                                cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[1].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster2if"
                              >
                                <input
                                  id="studiidreptmaster2if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Institutii de Drept Privat"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster2if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster2if"
                                    )
                                  }
                                />
                                Institutii de Drept Privat (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[2].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster3if"
                              >
                                <input
                                  id="studiidreptmaster3if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Drept intern și internațional al mediului"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster3if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster3if"
                                    )
                                  }
                                />
                                Drept intern și internațional al mediului
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[3].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster4if"
                              >
                                <input
                                  id="studiidreptmaster4if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Drept internațional și dreptul Uniunii Europene"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster4if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster4if"
                                    )
                                  }
                                />
                                Drept internațional și dreptul Uniunii Europene
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[4].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster5if"
                              >
                                <input
                                  id="studiidreptmaster5if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Drept internațional și european al afacerilor"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster5if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster5if"
                                    )
                                  }
                                />
                                Drept internațional și european al afacerilor
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[5].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster6if"
                              >
                                <input
                                  id="studiidreptmaster6if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Drept social român și european"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster6if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster6if"
                                    )
                                  }
                                />
                                Drept social român și european (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[6].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster7if"
                              >
                                <input
                                  id="studiidreptmaster7if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Dreptul informațiilor și al securității private"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster7if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster7if"
                                    )
                                  }
                                />
                                Dreptul informațiilor și al securității private
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[0].master[7].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiidreptmaster8if"
                              >
                                <input
                                  id="studiidreptmaster8if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de drept,master,if,Științe penale și criminalistică"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiidreptmaster8if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiidreptmaster8if"
                                    )
                                  }
                                />
                                Științe penale și criminalistică (IF-învățământ
                                cu frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Facultatea de Psihologie */}
                {!(
                  parseInt(facultati[1].uncheckedCountLicenta) ===
                    parseInt(facultati[1].licenta.length) &&
                  parseInt(facultati[1].uncheckedCountMaster) ===
                    parseInt(facultati[1].master.length)
                ) && (
                  <li className="facultati__li">
                    <label className="facultati__label" htmlFor="psihologie">
                      <input
                        id="psihologie"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        checked={clickedRadio === "psihologie"}
                        onChange={(e) => radioHandler("psihologie")}
                      />
                      Facultatea de Psihologie
                    </label>
                  </li>
                )}
                {clickedRadio === "psihologie" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[1].uncheckedCountLicenta) <
                        parseInt(facultati[1].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="psihologielicenta"
                          >
                            <input
                              id="psihologielicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "psihologielicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster("psihologielicenta")
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}
                      {parseInt(facultati[1].uncheckedCountMaster) <
                        parseInt(facultati[1].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="psihologiemaster"
                          >
                            <input
                              id="psihologiemaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster === "psihologiemaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster("psihologiemaster")
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster === "psihologielicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          <div className="facultati__licentaFrecvContainer">
                            {facultati[1].licenta[0].checked === true && (
                              <>
                                <label
                                  className="facultati__label studiu__label"
                                  htmlFor="studiipsihologielicenta1if"
                                >
                                  <input
                                    id="studiipsihologielicenta1if"
                                    className="facultati__input"
                                    type="radio"
                                    name="studii"
                                    value="facultatea de psihologie,licenta,if,psihologie"
                                    checked={
                                      clickedRadioStudii ===
                                      "studiipsihologielicenta1if"
                                    }
                                    onChange={(e) =>
                                      radioHandlerStudii(
                                        "studiipsihologielicenta1if"
                                      )
                                    }
                                  />
                                  Psihologie (IF-învățământ cu frecvență)
                                </label>
                              </>
                            )}
                            {facultati[1].licenta[1].checked === true && (
                              <>
                                <label
                                  className="facultati__label studiu__label"
                                  htmlFor="studiipsihologielicenta1ifr"
                                >
                                  <input
                                    id="studiipsihologielicenta1ifr"
                                    className="facultati__input"
                                    type="radio"
                                    name="studii"
                                    value="facultatea de psihologie,licenta,ifr,psihologie"
                                    checked={
                                      clickedRadioStudii ===
                                      "studiipsihologielicenta1ifr"
                                    }
                                    onChange={(e) =>
                                      radioHandlerStudii(
                                        "studiipsihologielicenta1ifr"
                                      )
                                    }
                                  />
                                  Psihologie (IFR-învățământ cu frecvență
                                  redusă)
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster === "psihologiemaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[1].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiipsihologiemaster1if"
                              >
                                <input
                                  id="studiipsihologiemaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de psihologie,master,if,Psihodiagnoză cognitivă și consiliere psihologică"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiipsihologiemaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiipsihologiemaster1if"
                                    )
                                  }
                                />
                                Psihodiagnoză cognitivă și consiliere
                                psihologică (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[1].master[1].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiipsihologiemaster2if"
                              >
                                <input
                                  id="studiipsihologiemaster2if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de psihologie,master,if,Psihologie aplicată în domeniul securității naționale"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiipsihologiemaster2if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiipsihologiemaster2if"
                                    )
                                  }
                                />
                                Psihologie aplicată în domeniul securității
                                naționale (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Facultatea de Ecologie si Protectia Mediului */}
                {!(
                  parseInt(facultati[2].uncheckedCountLicenta) ===
                    parseInt(facultati[2].licenta.length) &&
                  parseInt(facultati[2].uncheckedCountMaster) ===
                    parseInt(facultati[2].master.length)
                ) && (
                  <li className="facultati__li">
                    <label className="facultati__label" htmlFor="ecologie">
                      <input
                        id="ecologie"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        checked={clickedRadio === "ecologie"}
                        onChange={(e) => radioHandler("ecologie")}
                      />
                      Facultatea de Ecologie si Protectia Mediului
                    </label>
                  </li>
                )}
                {clickedRadio === "ecologie" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[2].uncheckedCountLicenta) <
                        parseInt(facultati[2].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="ecologielicenta"
                          >
                            <input
                              id="ecologielicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster === "ecologielicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster("ecologielicenta")
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}

                      {parseInt(facultati[2].uncheckedCountMaster) <
                        parseInt(facultati[2].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="ecologiemaster"
                          >
                            <input
                              id="ecologiemaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster === "ecologiemaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster("ecologiemaster")
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster === "ecologielicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          {facultati[2].licenta[0].checked === true && (
                            <div className="facultati__ifLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotectiamediuluilicenta1if"
                              >
                                <input
                                  id="studiiprotectiamediuluilicenta1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de ecologie și protecția mediului ,licenta,if,Ecologie și protecția mediului"
                                  checked={
                                    clickedRadioStudii ===
                                    "studiiprotectiamediuluilicenta1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "studiiprotectiamediuluilicenta1if"
                                    )
                                  }
                                />
                                Ecologie și protecția mediului (IF-învățământ cu
                                frecvență)
                              </label>
                            </div>
                          )}
                          {facultati[2].licenta[1].checked === true && (
                            <div className="facultati__ifrLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotectiamediuluilicenta1ifr"
                              >
                                <input
                                  id="studiiprotectiamediuluilicenta1ifr"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de ecologie și protecția mediului ,licenta,ifr,Ecologie și protecția mediului"
                                  checked={
                                    clickedRadioStudii ===
                                    "studiiprotectiamediuluilicenta1ifr"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "studiiprotectiamediuluilicenta1ifr"
                                    )
                                  }
                                />
                                Ecologie și protecția mediului (IFR-învățământ
                                cu frecvență redusă)
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster === "ecologiemaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[2].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotecțiamediuluimaster1if"
                              >
                                <input
                                  id="studiiprotecțiamediuluimaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de ecologie și protecția mediului,master,if,Evaluarea impactului asupra mediului"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiiprotecțiamediuluimaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiiprotecțiamediuluimaster1if"
                                    )
                                  }
                                />
                                Evaluarea impactului asupra mediului
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[2].master[1].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotecțiamediuluimaster2if"
                              >
                                <input
                                  id="studiiprotecțiamediuluimaster2if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de ecologie și protecția mediului,master,if,Gestionarea efectelor schimbărilor climatice"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiiprotecțiamediuluimaster2if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiiprotecțiamediuluimaster2if"
                                    )
                                  }
                                />
                                Gestionarea efectelor schimbărilor climatice
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[2].master[2].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotecțiamediuluimaster3if"
                              >
                                <input
                                  id="studiiprotecțiamediuluimaster3if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de ecologie și protecția mediului,master,if,Managementul resurselor naturale"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiiprotecțiamediuluimaster3if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiiprotecțiamediuluimaster3if"
                                    )
                                  }
                                />
                                Managementul resurselor naturale (IF-învățământ
                                cu frecvență)
                              </label>
                            </>
                          )}
                          {facultati[2].master[3].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotecțiamediuluimaster4if"
                              >
                                <input
                                  id="studiiprotecțiamediuluimaster4if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de ecologie și protecția mediului,master,if,GIS pentru știința mediului"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiiprotecțiamediuluimaster4if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiiprotecțiamediuluimaster4if"
                                    )
                                  }
                                />
                                GIS pentru știința mediului (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Facultatea de Educatie Fizica si Sport */}
                {!(
                  parseInt(facultati[3].uncheckedCountLicenta) ===
                    parseInt(facultati[3].licenta.length) &&
                  parseInt(facultati[3].uncheckedCountMaster) ===
                    parseInt(facultati[3].master.length)
                ) && (
                  <li className="facultati__li">
                    <label
                      className="facultati__label"
                      htmlFor="educatiefizica"
                    >
                      <input
                        id="educatiefizica"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        checked={clickedRadio === "educatiefizica"}
                        onChange={(e) => radioHandler("educatiefizica")}
                      />
                      Facultatea de Educatie Fizica si Sport
                    </label>
                  </li>
                )}
                {clickedRadio === "educatiefizica" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[3].uncheckedCountLicenta) <
                        parseInt(facultati[3].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="educatiefizicalicenta"
                          >
                            <input
                              id="educatiefizicalicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "educatiefizicalicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "educatiefizicalicenta"
                                )
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}

                      {parseInt(facultati[3].uncheckedCountMaster) <
                        parseInt(facultati[3].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="educatiefizicamaster"
                          >
                            <input
                              id="educatiefizicamaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "educatiefizicamaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "educatiefizicamaster"
                                )
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster ===
                        "educatiefizicalicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          <div className="facultati__ifLicentaStudii">
                            {facultati[3].licenta[0].checked === true && (
                              <>
                                <label
                                  className="facultati__label studiu__label"
                                  htmlFor="studiieducatiefizicalicenta1if"
                                >
                                  <input
                                    id="studiieducatiefizicalicenta1if"
                                    className="facultati__input"
                                    type="radio"
                                    name="studii"
                                    value="facultatea de educatie fizica si sport, licenta, if, Educație fizică și sportivă"
                                    checked={
                                      clickedRadioStudii ===
                                      "studiieducatiefizicalicenta1if"
                                    }
                                    onChange={(e) =>
                                      radioHandlerStudii(
                                        "studiieducatiefizicalicenta1if"
                                      )
                                    }
                                  />
                                  Educație fizică și sportivă (IF-învățământ cu
                                  frecvență)
                                </label>
                              </>
                            )}
                            {facultati[3].licenta[1].checked === true && (
                              <>
                                <label
                                  className="facultati__label studiu__label"
                                  htmlFor="studiieducatiefizicalicenta2if"
                                >
                                  <input
                                    id="studiieducatiefizicalicenta2if"
                                    className="facultati__input"
                                    type="radio"
                                    name="studii"
                                    value="facultatea de educatie fizica si sport, licenta, if, Kinetoterapie"
                                    checked={
                                      clickedRadioStudii ===
                                      "studiieducatiefizicalicenta2if"
                                    }
                                    onChange={(e) =>
                                      radioHandlerStudii(
                                        "studiieducatiefizicalicenta2if"
                                      )
                                    }
                                  />
                                  Kinetoterapie (IF-învățământ cu frecvență)
                                </label>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster === "educatiefizicamaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[3].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiieducatiefizicamaster1if"
                              >
                                <input
                                  id="studiieducatiefizicamaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de educatie fizica si sport,master,if,Educație fizică și sportivă"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiieducatiefizicamaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiieducatiefizicamaster1if"
                                    )
                                  }
                                />
                                Educație fizică și sportivă (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                          {facultati[3].master[1].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiiprotecțiamediuluimaster2if"
                              >
                                <input
                                  id="studiiprotecțiamediuluimaster2if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de educatie fizica si sport,master,if,Managementul activităților de educație fizică și sport"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiiprotecțiamediuluimaster2if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiiprotecțiamediuluimaster2if"
                                    )
                                  }
                                />
                                Managementul activităților de educație fizică și
                                sport (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Facultatea de Inginerie Manageriala */}
                {!(
                  parseInt(facultati[4].uncheckedCountLicenta) ===
                    parseInt(facultati[4].licenta.length) &&
                  parseInt(facultati[4].uncheckedCountMaster) ===
                    parseInt(facultati[4].master.length)
                ) && (
                  <li className="facultati__li">
                    <label
                      className="facultati__label"
                      htmlFor="ingineriemanageriala"
                    >
                      <input
                        id="ingineriemanageriala"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        checked={clickedRadio === "ingineriemanageriala"}
                        onChange={(e) => radioHandler("ingineriemanageriala")}
                      />
                      Facultatea de Inginerie Manageriala
                    </label>
                  </li>
                )}
                {clickedRadio === "ingineriemanageriala" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[4].uncheckedCountLicenta) <
                        parseInt(facultati[4].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="ingineriemanagerialalicenta"
                          >
                            <input
                              id="ingineriemanagerialalicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "ingineriemanagerialalicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "ingineriemanagerialalicenta"
                                )
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}

                      {parseInt(facultati[4].uncheckedCountMaster) <
                        parseInt(facultati[4].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="ingineriemanagerialamaster"
                          >
                            <input
                              id="ingineriemanagerialamaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "ingineriemanagerialamaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "ingineriemanagerialamaster"
                                )
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster ===
                        "ingineriemanagerialalicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          <div className="facultati__licentaFrecvContainer">
                            {facultati[4].licenta[0].checked === true && (
                              <div className="facultati__ifLicentaStudii">
                                <label
                                  className="facultati__label studiu__label"
                                  htmlFor="ingineriemanagerialalicenta1if"
                                >
                                  <input
                                    id="ingineriemanagerialalicenta1if"
                                    className="facultati__input"
                                    type="radio"
                                    name="studii"
                                    value="facultatea de inginerie managerială, licenta, if, Inginerie economică industrială"
                                    checked={
                                      clickedRadioStudii ===
                                      "ingineriemanagerialalicenta1if"
                                    }
                                    onChange={(e) =>
                                      radioHandlerStudii(
                                        "ingineriemanagerialalicenta1if"
                                      )
                                    }
                                  />
                                  Inginerie economică industrială (IF-învățământ
                                  cu frecvență)
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster ===
                        "ingineriemanagerialamaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[4].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="ingineriemanagerialamaster1if"
                              >
                                <input
                                  id="ingineriemanagerialamaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de inginerie managerială,master,if,Ingineria și managementul afacerilor în industrie"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "ingineriemanagerialamaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "ingineriemanagerialamaster1if"
                                    )
                                  }
                                />
                                Ingineria și managementul afacerilor în
                                industrie (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Facultatea de Management Financiar */}
                {!(
                  parseInt(facultati[5].uncheckedCountLicenta) ===
                    parseInt(facultati[5].licenta.length) &&
                  parseInt(facultati[5].uncheckedCountMaster) ===
                    parseInt(facultati[5].master.length)
                ) && (
                  <li className="facultati__li">
                    <label
                      className="facultati__label"
                      htmlFor="managementfinanciar"
                    >
                      <input
                        id="managementfinanciar"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        value="psihologie"
                        checked={clickedRadio === "managementfinanciar"}
                        onChange={(e) => radioHandler("managementfinanciar")}
                      />
                      Facultatea de Management Financiar
                    </label>
                  </li>
                )}
                {clickedRadio === "managementfinanciar" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[5].uncheckedCountLicenta) <
                        parseInt(facultati[5].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="managementfinanciarlicenta"
                          >
                            <input
                              id="managementfinanciarlicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "managementfinanciarlicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "managementfinanciarlicenta"
                                )
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}

                      {parseInt(facultati[5].uncheckedCountMaster) <
                        parseInt(facultati[5].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="managementfinanciarmaster"
                          >
                            <input
                              id="managementfinanciarmaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "managementfinanciarmaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "managementfinanciarmaster"
                                )
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster ===
                        "managementfinanciarlicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          {facultati[5].licenta[0].checked === true && (
                            <div className="facultati__ifLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiimanagementfinanciarlicenta1if"
                              >
                                <input
                                  id="studiimanagementfinanciarlicenta1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de management financiar, licenta, if, Finanțe și bănci"
                                  checked={
                                    clickedRadioStudii ===
                                    "studiimanagementfinanciarlicenta1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "studiimanagementfinanciarlicenta1if"
                                    )
                                  }
                                />
                                Finanțe și bănci (IF-învățământ cu frecvență)
                              </label>
                            </div>
                          )}
                          {facultati[5].licenta[1].checked === true && (
                            <div className="facultati__ifrLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiimanagementfinanciarlicenta1ifr"
                              >
                                <input
                                  id="studiimanagementfinanciarlicenta1ifr"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de management financiar, licenta, ifr, Finanțe și bănci"
                                  checked={
                                    clickedRadioStudii ===
                                    "studiimanagementfinanciarlicenta1ifr"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "studiimanagementfinanciarlicenta1ifr"
                                    )
                                  }
                                />
                                Finanțe și bănci (IFR-învățământ cu frecvență
                                redusă)
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster ===
                        "managementfinanciarmaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[5].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiimanagementfinanciarmaster1if"
                              >
                                <input
                                  id="studiimanagementfinanciarmaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de management financiar,master,if,Finanțarea protecției sociale"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiimanagementfinanciarmaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiimanagementfinanciarmaster1if"
                                    )
                                  }
                                />
                                Finanțarea protecției sociale (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                          {facultati[5].master[1].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiimanagementfinanciarmaster2if"
                              >
                                <input
                                  id="studiimanagementfinanciarmaster2if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de management financiar,master,if,Management financiar"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiimanagementfinanciarmaster2if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiimanagementfinanciarmaster2if"
                                    )
                                  }
                                />
                                Management financiar (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                          {facultati[5].master[2].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiimanagementfinanciarmaster3if"
                              >
                                <input
                                  id="studiimanagementfinanciarmaster3if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de management financiar,master,if,Managementul financiar al mediului"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiimanagementfinanciarmaster3if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiimanagementfinanciarmaster3if"
                                    )
                                  }
                                />
                                Managementul financiar al mediului
                                (IF-învățământ cu frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Facultatea de Stiintele Comunicarii */}
                {!(
                  parseInt(facultati[6].uncheckedCountLicenta) ===
                    parseInt(facultati[6].licenta.length) &&
                  parseInt(facultati[6].uncheckedCountMaster) ===
                    parseInt(facultati[6].master.length)
                ) && (
                  <li className="facultati__li">
                    <label
                      className="facultati__label"
                      htmlFor="stiintelecomunicararii"
                    >
                      <input
                        id="stiintelecomunicararii"
                        className="facultati__input"
                        type="radio"
                        name="facultati"
                        checked={clickedRadio === "stiintelecomunicararii"}
                        onChange={(e) => radioHandler("stiintelecomunicararii")}
                      />
                      Facultatea de Stiintele Comunicarii
                    </label>
                  </li>
                )}
                {clickedRadio === "stiintelecomunicararii" && (
                  <>
                    {/* licenta & master */}
                    <div className="facultati__levelContainer">
                      {parseInt(facultati[6].uncheckedCountLicenta) <
                        parseInt(facultati[6].licenta.length) && (
                        <div className="facultati__licentaContainer">
                          <label
                            className="facultati__label"
                            htmlFor="stiintelecomunicariilicenta"
                          >
                            <input
                              id="stiintelecomunicariilicenta"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "stiintelecomunicariilicenta"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "stiintelecomunicariilicenta"
                                )
                              }
                            />
                            Licenta
                          </label>
                        </div>
                      )}

                      {parseInt(facultati[6].uncheckedCountMaster) <
                        parseInt(facultati[6].master.length) && (
                        <div className="facultati__masterContainer">
                          <label
                            className="facultati__label"
                            htmlFor="stiintelecomunicariimaster"
                          >
                            <input
                              id="stiintelecomunicariimaster"
                              className="facultati__input"
                              type="radio"
                              name="licentamaster"
                              checked={
                                clickedRadioLicentaMaster ===
                                "stiintelecomunicariimaster"
                              }
                              onChange={(e) =>
                                radioHandlerLicentaMaster(
                                  "stiintelecomunicariimaster"
                                )
                              }
                            />
                            Master
                          </label>
                        </div>
                      )}
                    </div>

                    <div className="facultati__licentaMasterFrecvNonContainer">
                      {/* licenta */}
                      {clickedRadioLicentaMaster ===
                        "stiintelecomunicariilicenta" && (
                        <div className="facultati__licentaFrecvNonContainer">
                          {facultati[6].licenta[0].checked === true && (
                            <div className="facultati__ifLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiistiintelecomunicariilicenta1if"
                              >
                                <input
                                  id="studiistiintelecomunicariilicenta1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de stiintele comunicării, licenta, if, Comunicare și relații publice"
                                  checked={
                                    clickedRadioStudii ===
                                    "studiistiintelecomunicariilicenta1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "studiistiintelecomunicariilicenta1if"
                                    )
                                  }
                                />
                                Comunicare și relații publice (IF-învățământ cu
                                frecvență)
                              </label>
                            </div>
                          )}
                          {facultati[6].licenta[1].checked === true && (
                            <div className="facultati__ifrLicentaStudii">
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiistiintelecomunicariilicenta1ifr"
                              >
                                <input
                                  id="studiistiintelecomunicariilicenta1ifr"
                                  className="facultati__input"
                                  type="radio"
                                  name="studii"
                                  value="facultatea de stiintele comunicării, licenta, ifr, Comunicare și relații publice"
                                  checked={
                                    clickedRadioStudii ===
                                    "studiistiintelecomunicariilicenta1ifr"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudii(
                                      "studiistiintelecomunicariilicenta1ifr"
                                    )
                                  }
                                />
                                Comunicare și relații publice (IFR-învățământ cu
                                frecvență redusă)
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                      {/* master */}
                      {clickedRadioLicentaMaster ===
                        "stiintelecomunicariimaster" && (
                        <div className="facultati__masterFrecvContainer">
                          {facultati[6].master[0].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiistiintelecomunicariimaster1if"
                              >
                                <input
                                  id="studiistiintelecomunicariimaster1if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de stiintele comunicării,master,if,Comunicare și relații publice"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiistiintelecomunicariimaster1if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiistiintelecomunicariimaster1if"
                                    )
                                  }
                                />
                                Comunicare și relații publice (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                          {facultati[6].master[1].checked === true && (
                            <>
                              <label
                                className="facultati__label studiu__label"
                                htmlFor="studiistiintelecomunicariimaster2if"
                              >
                                <input
                                  id="studiistiintelecomunicariimaster2if"
                                  className="facultati__input"
                                  type="radio"
                                  name="studiimaster"
                                  value="facultatea de stiintele comunicării,master,if,Comunicare și new media"
                                  checked={
                                    clickedRadioStudiiMaster ===
                                    "studiistiintelecomunicariimaster2if"
                                  }
                                  onChange={(e) =>
                                    radioHandlerStudiiMaster(
                                      "studiistiintelecomunicariimaster2if"
                                    )
                                  }
                                />
                                Comunicare și new media (IF-învățământ cu
                                frecvență)
                              </label>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </ul>
            </div>
          </Form>

          <div className="form__containerButon">
            <Button onClick={next}>PASUL 2</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ListaFacultati;

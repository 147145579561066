import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import FilterableTable from "react-filterable-table";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import { AuthContext } from "../../shared/components/Context/auth-context";
import "./Admin.css";
import { CSVLink, CSVDownload } from "react-csv";

const Admin = () => {
  const auth = useContext(AuthContext);
  const [loadedUsers, setLoadedUsers] = useState();
  const [isLoadingUsers, setIsLoadingUsers] = useState();
  const [error, setError] = useState();
  const [dataToCSV, setDataToCsv] = useState();
  const [isLoadedDataToCsv, setIsLoadedDataToCsv] = useState();
  //console.log("auth ",auth);
  useEffect(() => {
    //console.log(props.item._id);
    setIsLoadingUsers(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          `https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/byadmin/${auth.userFacultate}`
          //`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/byadmin/${auth.userFacultate}`
        );
        const responseData = await response.json();
        //console.log(responseData.data);
        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setLoadedUsers(responseData.data);
      } catch (err) {
        setError(err.message || "Probleme la fetch users by admin");

        setIsLoadingUsers(false);
        setError(err.message);
      }
      //console.log(loadedMenuReviews);
      setIsLoadingUsers(false);
    };

    sendRequest();
  }, [setIsLoadingUsers, auth.userFacultate]);
  //console.log(loadedUsers);

  const renderDate = (props) => {
    return props.value.split("T")[0];
  };

  const renderName = (props) => {
    return (
      <Link to={`/user/${props.record._id}`} target="_blank">
        {props.value}
      </Link>
    );
  };

  const renderPlata = (props) => {
    return props.value !== "false" && props.value !== "true" ? "DA" : "NU";
  };

  // campurile pentru tabel si datele ce vor fi afisate
  const fields = [
    {
      name: "firstBornName",
      displayName: "N.nastere",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "firstActualName",
      displayName: "N.actual",
      inputFilterable: true,
      sortable: true,
      render: renderName,
    },
    {
      name: "lastName",
      displayName: "Pren",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "mediabac",
      displayName: "M BAC",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "medialicenta",
      displayName: "M Lic",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "email",
      displayName: "Email",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "facultate",
      displayName: "Facult",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "nivel",
      displayName: "Nivel",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "program",
      displayName: "Prog",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "ififr",
      displayName: "IF/IFR",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "verifiedBy",
      displayName: "Verif",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "currentDistrict",
      displayName: "Jud",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "platacucardul",
      displayName: "Plata",
      inputFilterable: true,
      sortable: true,
      render: renderPlata,
    },
    {
      name: "createdAt",
      displayName: "Dată",
      inputFilterable: true,
      sortable: true,
      render: renderDate,
    },
  ];

  const errorHandler = () => {
    setError(null);
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setIsLoadedDataToCsv(true);
        const response = await fetch(
          `https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/getCSV/${auth.userFacultate}`
        );
        const responseData = await response.json();
        console.log(responseData);

        setDataToCsv(responseData.data);
        // Throw error

        if (!response.ok) {
          throw new Error(responseData.error);
        }
        setIsLoadedDataToCsv(false);
      } catch (err) {
        setError(err.message || "Probleme la descarcare CSV users by admin");
        setError(err.message);
      }
      setIsLoadedDataToCsv(false);
    };
    sendRequest();
  }, [auth.userFacultate]);

  const headers = [
    { label: "Nume la nastere", key: "firstBornName" },
    { label: "Nume actual", key: "firstActualName" },
    { label: "Prenume", key: "lastName" },
    { label: "CNP", key: "cnp" },
    { label: "Data Nasterii", key: "bornDate" },
    { label: "Localitatea nasterii", key: "bornPlace" },
    { label: "Judetul nasterii", key: "bornDistrict" },
    { label: "Tara nasterii", key: "bornCountry" },
    { label: "Seria CI", key: "seriesCart" },
    { label: "Numarul CI", key: "numberCart" },
    { label: "Eliberat de", key: "setCart" },
    { label: "Data eliberarii", key: "releaseDateCart" },
    { label: "Localitatea de domiciliu", key: "currentCity" },
    { label: "Judetul de domiciliu", key: "currentDistrict" },
    { label: "Tara de domiciliu", key: "currentCountry" },
    { label: "Telefon", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Facultatea", key: "facultate" },
    { label: "Licenta/Master", key: "nivel" },
    { label: "If/IFR", key: "ififr" },
    { label: "Programul de studiu", key: "program" },
    { label: "Media licenta", key: "medialicenta" },
    { label: "Media Bacalaureat", key: "mediabac" },
    { label: "Plata cu card", key: "platacucardul" },
    { label: "Verificat", key: "verifiedBy" },
  ];

  return (
    <>
      {auth.isLoggedIn && (
        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          <Link style={{ float: "right" }} to="/signin" onClick={auth.logout}>
            <i className="fas fa-sign-out-alt"></i>Signout
          </Link>
        </div>
      )}
      <ErrorModal error={error} onClear={errorHandler} />
      {!isLoadingUsers && loadedUsers && (
        <>
          <FilterableTable
            tableClassName="mytabel"
            namespace="People"
            initialSort="name"
            data={loadedUsers}
            fields={fields}
            noRecordsMessage="Nu sunt date de afisat"
            noFilteredRecordsMessage="Niciun rezultat!"
          />
          {!isLoadedDataToCsv && dataToCSV && (
            <div className="csvlink__container">
              <CSVLink
                data={dataToCSV}
                headers={headers}
                filename={"candidati.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                DESCARCA CSV
              </CSVLink>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Admin;

import React, { useCallback, useReducer, useContext, useState } from "react";

import { CSSTransition } from "react-transition-group";

import Form from "../../shared/components/UIElements/Form";

import Button from "../../shared/components/FormElements/Button";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import Input from "../../shared/components/FormElements/Input";

import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";

import { AuthContext } from "../../shared/components/Context/auth-context";

import "./UserSignin.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,

        inputs: {
          ...state.inputs,

          [action.inputId]: { value: action.value, isValid: action.isValid },
        },

        isValid: formIsValid,
      };

    default:
      return state;
  }
};

const UserSignin = () => {
  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      userName: {
        value: "",

        isValid: false,
      },

      password: {
        value: "",

        isValid: false,
      },
    },

    isValid: false,
  });

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,
      });
    },

    [dispatch]
  );

  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState();

  const userSigninHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      setIsLoading(true);

      const response = await fetch(
        //"https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/auth/signin",
        "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/auth/signin",
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify({
            userName: formState.inputs.userName.value,

            password: formState.inputs.password.value,
          }),
        }
      );

      const responseData = await response.json();
      console.log("data: ", responseData);
      // Throw error

      if (!response.ok) {
        throw new Error(responseData.error);
      }

      setIsLoading(false);

      auth.login(
        responseData.userId,

        responseData.token,

        responseData.userRole,

        responseData.userName,

        responseData.userFacultate
      );
    } catch (err) {
      setError(err.message || "Something went wrong please try again");

      setIsLoading(false);
    }
  };

  const errorHandler = () => {
    setError(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={errorHandler} />

      <CSSTransition
        classNames={{
          appear: "slide-in-left-enter",

          appearActive: "slide-in-left-enter-active",
        }}
        timeout={1000}
        in
        appear
        unmountOnExit
      >
        <section className="container user-signin-container">
          {isLoading && <LoadingSpinner asOverlay />}

          <h1 className="large text-primary">CONECTARE</h1>

          <p className="lead">
            <i className="fas fa-user"></i> Conectare în cont
          </p>

          <Form className="form" onSubmit={userSigninHandler}>
            <div className="form-group">
              <Input
                id="userName"
                element="input"
                type="text"
                placeholder="USER NAME"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Please enter a valid USER NAME"
                onInput={inputHandler}
              />
            </div>

            <div className="form-group">
              <Input
                id="password"
                element="input"
                type="password"
                placeholder="PASSWORD"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Minimum password is 8 characters"
                onInput={inputHandler}
              />
            </div>

            <Button id="btnSubmit" type="submit" disabled={!formState.isValid}>
              Sign In
            </Button>
          </Form>
        </section>
      </CSSTransition>
    </React.Fragment>
  );
};

export default UserSignin;

import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../shared/components/Context/auth-context";

import Button from "../../shared/components/FormElements/Button";
import Form from "../../shared/components/UIElements/Form";
import Modal from "../../shared/components/UIElements/Modal";
import "./SuperAdmin.css";

const SuperAdmin = () => {
  const auth = useContext(AuthContext);
  const [error, setError] = useState();
  const [checkBoxes, setCheckBoxes] = useState([]);
  const [reqDone, setReqDone] = useState(false);
  const [isLoadedData, setIsLoadedData] = useState();
  const [isLoadedDataPost, setIsLoadedDataPost] = useState();

  //Modal
  const [showSuccess, setShowSuccess] = useState(false);
  const openShowSuccesHandler = () => setShowSuccess(true);

  //const closeShowSuccesHandler = () => setShowSuccess(false);

  //get data from DB
  useEffect(() => {
    setIsLoadedData(true);
    const sendRequest = async () => {
      try {
        const response = await fetch(
          //`https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/superAdmin`
          `https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/superAdmin`
        );
        const responseData = await response.json();
        //console.log("get: ", responseData.data);

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setCheckBoxes(responseData.data);
      } catch (err) {
        setError(err.message || "Probleme la fetch facultati");

        setIsLoadedData(false);
        setError(err.message);
      }
      setIsLoadedData(false);
    };

    sendRequest();
  }, [setIsLoadedData]);

  const checkBoxHandler = async (itemChecked) => {
    checkBoxes &&
      checkBoxes.map((item) => {
        if (item.licenta) {
          let uncheckedCount = 0;
          item.licenta.find((facult) => {
            if (itemChecked.id === facult.id) {
              facult.checked = !facult.checked;
            }
            if (facult.checked === false) {
              uncheckedCount++;
            }
          });
          /*  console.log(
            "item.uncheckedCountLicenta: ",
            item.uncheckedCountLicenta
          ); */

          item.uncheckedCountLicenta = uncheckedCount;
          /* console.log(
            "item.uncheckedCountLicenta after: ",
            item.uncheckedCountLicenta
          ); */
        }
        if (item.master) {
          let uncheckedCount = 0;
          item.master.find((master) => {
            if (itemChecked.id === master.id) {
              master.checked = !master.checked;
            }
            if (master.checked === false) {
              uncheckedCount++;
            }
          });
          item.uncheckedCountMaster = uncheckedCount;
        }
      });

    setCheckBoxes([...checkBoxes]);
  };

  const saveSettingsX = () => {
    console.log("{ facultati: checkBoxes } :", { facultati: checkBoxes });
    setIsLoadedDataPost(true);

    const sendRequest = async () => {
      try {
        const response = await fetch(
          `https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/superAdmin/saveFacultati`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },

            body: JSON.stringify({ facultati: checkBoxes }),
          }
        );
        const responseData = await response.json();

        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        } else {
          setReqDone(true);
          openShowSuccesHandler();
        }

        //setLoadedUsers(responseData.data);
      } catch (err) {
        console.log("err cat : ", err);
        openShowSuccesHandler();
        setIsLoadedDataPost(false);
        setError(err.message);
      }
      setIsLoadedDataPost(false);
    };

    sendRequest();
  };

  //Modal
  const handlerCloseModal = () => {
    setShowSuccess(false);
  };
  useEffect(() => {
    console.log(isLoadedDataPost);
  }, [error]);
  return (
    <>
      {error && !isLoadedDataPost && (
        <Modal
          show={showSuccess}
          // onCancel={closeShowSuccessHandler}

          header={"Success"}
          contentClass="menu-selected__modal-content"
          footerClass="menu-selected__modal-actions"
        >
          <p>{error}</p>

          <footer>
            <Button className="btn" onClick={handlerCloseModal}>
              Ok
            </Button>
          </footer>
        </Modal>
      )}

      {reqDone === true && (
        <Modal
          show={showSuccess}
          // onCancel={closeShowSuccessHandler}

          header={"Success"}
          contentClass="menu-selected__modal-content"
          footerClass="menu-selected__modal-actions"
        >
          <p>Salvare cu succes</p>
          <footer>
            <Button className="btn" onClick={handlerCloseModal}>
              Ok
            </Button>
          </footer>
        </Modal>
      )}
      {isLoadedData && <div>Loading...</div>}
      {!isLoadedData && auth.isLoggedIn && (
        <div style={{ position: "relative", width: "100%", marginTop: "10px" }}>
          <Link style={{ float: "right" }} to="/signin" onClick={auth.logout}>
            <i className="fas fa-sign-out-alt"></i>Signout
          </Link>
        </div>
      )}
      {!isLoadedData && (
        <>
          <Form className="form">
            <div className="form-group">
              <ul className="facultati__ul">
                {checkBoxes.map((item) => {
                  return (
                    <li className="facultati__liI">
                      <div className="facultati__nameE">{item.facultName}</div>
                      {/* licenta & master */}
                      <div className="facultati__levelContainerR">
                        <div className="facultati__licentaContainerR">
                          <label
                            className="facultati__label"
                            htmlFor="dreptlicenta"
                          >
                            {item.licenta && "Licenta"}
                          </label>
                        </div>

                        <div className="facultati__masterContainerR">
                          <label
                            className="facultati__label"
                            htmlFor="dreptmaster"
                          >
                            {item.master && "Master"}
                          </label>
                        </div>
                      </div>
                      <div className="facultati__licentaMasterFrecvNonContainerR">
                        <div className="facultati__licentaFrecvNonContainerR">
                          {item.licenta && (
                            <div>
                              {item.licenta.map((item) => {
                                return (
                                  <>
                                    <div>{item.name}</div>
                                    <input
                                      className="facultati__input studiu_input"
                                      type="checkbox"
                                      value={item.id}
                                      checked={item.checked}
                                      onClick={() => checkBoxHandler(item)}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>
                        <div className="facultati__masterFrecvContainerR">
                          {item.master && (
                            <div>
                              {item.master.map((item) => {
                                return (
                                  <>
                                    <div>{item.name}</div>
                                    <input
                                      className="facultati__input studiu_input"
                                      type="checkbox"
                                      value={item.id}
                                      checked={item.checked}
                                      onClick={() => checkBoxHandler(item)}
                                    />
                                  </>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Form>
          <div className="form__containerButonN">
            <Button onClick={saveSettingsX}>Save</Button>
          </div>
        </>
      )}
    </>
  );
};

export default SuperAdmin;

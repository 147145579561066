import React, { useState, useReducer, useCallback } from "react";

import ImageUpload from "../../shared/components/FormElements/ImageUpload";

import FileUpload from "../../shared/components/FormElements/FileUpload";

import Button from "../../shared/components/FormElements/Button";

import Modal from "../../shared/components/UIElements/Modal";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import "./UploadDocuments.css";

const formReducer = (state, action) => {
  switch (action.type) {
    case "INPUT_CHANGE":
      let formIsValid = true;

      for (const inputId in state.inputs) {
        if (inputId === action.inputId) {
          formIsValid = formIsValid && action.isValid;
        } else {
          formIsValid = formIsValid && state.inputs[inputId].isValid;
        }
      }

      return {
        ...state,

        inputs: {
          ...state.inputs,

          [action.inputId]: {
            value: action.value,
            isValid: action.value !== "" ? true : action.isValid,
          },
        },

        isValid: formIsValid,
      };

    default:
      return state;
  }
};

const UploadDocuments = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [formState, dispatch] = useReducer(formReducer, {
    inputs: {
      imagine: {
        value: "",

        isValid: true,
      },
      diplomabac: {
        value: "",

        isValid: true,
      },
      foaiematricola: {
        value: "",

        isValid: true,
      },
      diplomalicenta: {
        value: "",

        isValid: true,
      },
      suplimentdiploma: {
        value: "",

        isValid: true,
      },
      certificatnastere: {
        value: "",

        isValid: true,
      },
      certificatcasatorie: {
        value: "",

        isValid: true,
      },
      carteidentitate: {
        value: "",

        isValid: true,
      },
      adeverintamedicala: {
        value: "",

        isValid: true,
      },
      chitantaadmitere: {
        value: "",

        isValid: true,
      },
    },

    isValid: true,
  });

  const inputHandler = useCallback(
    (id, value, isValid) => {
      dispatch({
        type: "INPUT_CHANGE",

        value: value,

        isValid: isValid,

        inputId: id,
      });
    },
    [dispatch]
  );

  const errorHandler = () => {
    setError(null);
  };

  const saveDocs = () => {
    const { userId } = props;
    //console.log(formState);
    const sendRequest = async () => {
      try {
        setIsLoading(true);
        const formData = new FormData();

        formData.append("imagine", formState.inputs.imagine.value);
        formData.append("diplomabac", formState.inputs.diplomabac.value);
        formData.append(
          "foaiematricola",
          formState.inputs.foaiematricola.value
        );
        formData.append(
          "diplomalicenta",
          formState.inputs.diplomalicenta.value
        );
        formData.append(
          "suplimentdiploma",
          formState.inputs.suplimentdiploma.value
        );
        formData.append(
          "certificatnastere",
          formState.inputs.certificatnastere.value
        );
        formData.append(
          "certificatcasatorie",
          formState.inputs.certificatcasatorie.value
        );
        formData.append(
          "carteidentitate",
          formState.inputs.carteidentitate.value
        );
        formData.append(
          "adeverintamedicala",
          formState.inputs.adeverintamedicala.value
        );
        formData.append(
          "chitantaadmitere",
          formState.inputs.chitantaadmitere.value
        );

        formData.append("userId", userId);

        const response = await fetch(
          "https://ri2zqgv3xc.bnode.webrahost.ro/ueb/api/v1/users/adminsavefiles",
          {
            method: "POST",
            headers: {},
            body: formData,
          }
        );
        const responseData = await response.json();
        // Throw error
        if (!response.ok) {
          throw new Error(responseData.error);
        }

        setIsLoading(false);
        openShowSuccesHandlerSaveFile(true);
      } catch (err) {
        if (err.message === "error") {
          setError("Eroare | Datele corectate de admin nu s-au trimis");
        } else {
          setError("Eroare trimitere date de corectare admin");
        }
        setIsLoading(false);
      }
    };
    sendRequest();
  };

  const [showSuccessSaveFiles, setShowSuccessSaveFiles] = useState(false);
  const openShowSuccesHandlerSaveFile = () => setShowSuccessSaveFiles(true);
  const closeSuccesHandlerSaveFile = () => {
    setShowSuccessSaveFiles(false);
    window.location.reload(false);
  };

  return (
    <>
      <Modal
        show={showSuccessSaveFiles}
        header={"Success"}
        contentClass=""
        footerClass=""
      >
        <p style={{ fontSize: "22px", marginBottom: "20px" }}>
          Fisierele au fost salvate cu succes
        </p>

        <footer>
          <Button className="btn" onClick={closeSuccesHandlerSaveFile}>
            OK
          </Button>
        </footer>
      </Modal>
      <ErrorModal error={error} onClear={errorHandler} />
      <div className="updocs__container">
        <h3 style={{ textAlign: "center" }}>ATASARE/SUPRASCRIERE DOCUMENTE</h3>
        <div className="updocs__maincontainer">
          <div className="form-uploadDoc">
            <div className="form-group-uploaddoc">
              <div className="updocs__labelinput">
                <label htmlFor="image" className="updocs__label">
                  FOTOGRAFIE (poza), color, recentă{" "}
                </label>
                <ImageUpload
                  id="imagine"
                  onInput={inputHandler}
                  val={formState.inputs.imagine.value}
                />
              </div>
              <div className="updocs__labelinput">
                <label htmlFor="diplomabac" className="updocs__label">
                  <p>
                    INCARCA DIPLOMA DE BACALAUREAT/ADEVERINTA PENTRU PROMOTIA
                    CURENTA 2024
                  </p>
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="diplomabac"
                  onInput={inputHandler}
                  val={formState.inputs.diplomabac.value}
                />
              </div>
              <div className="updocs__labelinput">
                <label htmlFor="foaiematricola" className="updocs__label">
                  INCARCA FOAIA MATRICOLA (daca Diploma de Bacalaureat a fost
                  însoțită de aceasta)
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="foaiematricola"
                  onInput={inputHandler}
                  val={formState.inputs.foaiematricola.value}
                />
              </div>
              {/* only for master */}

              <div className="updocs__labelinput">
                <label htmlFor="diplomalicenta" className="updocs__label">
                  DIPLOMA DE LICENTA/ADEVERINTA PENTRU PROMOTIA CURENTA(2024){" "}
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="diplomalicenta"
                  onInput={inputHandler}
                  val={formState.inputs.diplomalicenta.value}
                />
              </div>
              <div className="updocs__labelinput">
                <label htmlFor="suplimentdiploma" className="updocs__label">
                  SUPLIMENTUL LA DIPLOMA SAU FOAIA MATRICOLA{" "}
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="suplimentdiploma"
                  onInput={inputHandler}
                  val={formState.inputs.suplimentdiploma.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="certificatnastere" className="updocs__label">
                  INCARCA CERTIFICATUL DE NASTERE{" "}
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="certificatnastere"
                  onInput={inputHandler}
                  val={formState.inputs.certificatnastere.value}
                />
              </div>
              <div className="updocs__labelinput">
                <label htmlFor="certificatcasatorie" className="updocs__label">
                  CERTIFICAT DE CASATORIE/ACTUL ADMINISTRATIV (în cazul
                  schimbării numelui)
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="certificatcasatorie"
                  onInput={inputHandler}
                  val={formState.inputs.certificatcasatorie.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="carteidentitate" className="updocs__label">
                  CARTE DE IDENTITATE
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="carteidentitate"
                  onInput={inputHandler}
                  val={formState.inputs.carteidentitate.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="adeverintamedicala" className="updocs__label">
                  ADEVERINTA MEDICALA (din care să rezulte că este sunteți apt
                  să urmați studii superioare, iar pentru Facultatea de Educație
                  Fizică și Sport în adeverință se menționează "apt pentru efort
                  fizic")
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="adeverintamedicala"
                  onInput={inputHandler}
                  val={formState.inputs.adeverintamedicala.value}
                />
              </div>

              <div className="updocs__labelinput">
                <label htmlFor="chitantaadmitere" className="updocs__label">
                  CHITANTA TAXA DE INSCRIERE
                </label>
                <FileUpload
                  buttonText="INCARCA"
                  id="chitantaadmitere"
                  onInput={inputHandler}
                  val={formState.inputs.chitantaadmitere.value}
                />
              </div>
            </div>
            <div className="form__containerButon">
              <Button onClick={saveDocs}>SALVEAZA</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocuments;

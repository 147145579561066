import React from 'react';
import './Header.css';

const Header = () => {

    return(
        <>
        <div className="header__container">
           <img
            className="header__img"
            src={require("../../shared/images/logo_admitere_UEB.png")}
            alt="UEB"
            />
        </div>         
        </>
    );
}

export default Header;
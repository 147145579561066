import React, { useContext } from "react";
import Admin from "../../admin/components/Admin";
import SuperAdmin from "../../admin/components/SuperAdmin";
import { AuthContext } from "../../shared/components/Context/auth-context";

import "./AdminPage.css";

const AdminPage = () => {
  const auth = useContext(AuthContext);
  //console.log("auth ", auth);
  /* isLoggedIn: true
    userName: "admin_adminX"*/

  return (
    <div className="container">
      {auth.userName === "admin_adminX" && auth.isLoggedIn === true && (
        <SuperAdmin />
      )}
      {auth.userName !== "admin_adminX" && auth.isLoggedIn === true && (
        <Admin />
      )}
    </div>
  );
};

export default AdminPage;

import React from 'react';

import { useParams } from "react-router-dom";

import UserDetailsPage from '../components/UserDetailsPage';
import UploadDocuments from '../components/UploadDocuments';

import './AdminUserDetailsPage.css';

const AdminUserDetailsPage = () => {
const userId = useParams().userId;

    return(
        <div className="admin__userpage--container">               
         <UserDetailsPage userId={userId}/> 
         <UploadDocuments userId={userId}/>        
        </div>
    );
}

export default AdminUserDetailsPage;
import React from 'react';

import {
  BrowserRouter as Router,
  Route,  
  Switch,
  Redirect
} from "react-router-dom";

import './App.css';
import AdminPage from './admin/pages/AdminPage';
import AdminUserDetailsPage from './admin/pages/AdminUserDetailsPage';
import MultiStepFormPage from './user/pages/MultiStepFormPage';


import { AuthContext } from "./shared/components/Context/auth-context";
import { useAuth } from "./shared/hooks/auth-hooks";
import Signin from "./accounts/pages/Signin";

const App = () => { 

  const { token, login, logout, userId, userRole, userName, userFacultate } = useAuth();
 
  let routes;

  if (token) {
    routes = (
      <Switch>
        <Route path="/pas-1" exact>
          <MultiStepFormPage />
        </Route>      
        <Route path="/qatyhlpwqe44s-7uio-z123$wsx456$" exact>
          <AdminPage />
        </Route>
        <Route path="/user/:userId" exact>
          <AdminUserDetailsPage />
        </Route>
        <Route path='/finalizat' component={() => { 
            window.location.href = 'https://ueb.ro/'; 
            return null;
        }}/> 
        <Route path='/plata-cu-card-online' component={() => { 
            window.location.href = 'https://www.ueb.ro/platacard/';
            return null;
        }}/>  
        <Redirect to="/qatyhlpwqe44s-7uio-z123$wsx456$" />          
      </Switch>         
      );
    } 
    else {
      routes = (
        <Switch>
        <Route path="/pas-1" exact>
          <MultiStepFormPage />
        </Route>      
        <Route path='/finalizat' component={() => { 
            window.location.href = 'https://ueb.ro/'; 
            return null;
        }}/> 
         <Route path="/signin" exact>
          <Signin />
        </Route>
        <Route path='/plata-cu-card-online' component={() => { 
            window.location.href = 'https://www.ueb.ro/platacard/'; 
            return null;
        }}/>            
      </Switch>         
      );
    }
    return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,

        token: token,

        userId: userId,

        userRole: userRole,

        userName: userName,

        userFacultate: userFacultate,

        login: login,

        logout: logout
      }}
    >
        <Router>
          <main>{routes}</main>
        </Router>
      
    </AuthContext.Provider>
  );

}

export default App;
